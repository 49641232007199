import React from 'react';
import autobind from 'autobind-decorator';
import {IProvidedGlobalProps, withGlobalProps} from '../providers/GlobalPropsProvider';
import {PriceSlider} from '../PriceSlider/PriceSlider';
import {IFilterOption, IRangeValue} from '../../types/types';

export interface IRangeFilterProps extends IProvidedGlobalProps {
  filterId: number;
  options: IFilterOption[];
  selectedRange: {
    min: string;
    max: string;
  };
}

interface IRangeFilterState {
  value: IRangeValue;
}

@withGlobalProps
export class RangeFilter extends React.Component<IRangeFilterProps, IRangeFilterState> {
  constructor(props) {
    super(props);

    this.state = {
      value: {
        min: 0,
        max: this.props.options.length - 1,
      },
    };
  }

  public static getDerivedStateFromProps(nextProps) {
    if (nextProps.selectedRange.max === '' && nextProps.selectedRange.min === '') {
      return {value: {min: 0, max: nextProps.options.length - 1}};
    }

    /* istanbul ignore next */
    return null;
  }

  public render() {
    return (
      <PriceSlider
        formatLabel={(_value, type) => {
          return this.state.value.hasOwnProperty(type) ? this.props.options[this.state.value[type]].value : '';
        }}
        value={this.state.value}
        minValue={0}
        maxValue={this.props.options.length - 1}
        onChangeComplete={this.onChangeComplete}
        onChange={(value: IRangeValue) => this.setState({value})}
      />
    );
  }

  @autobind
  private onChangeComplete(rangeValue: IRangeValue) {
    const {min: minIndex, max: maxIndex} = rangeValue;

    const value = {
      min: this.props.options[minIndex].key,
      max: this.props.options[maxIndex].key,
    };
    this.props.globals.filterProducts(this.props.filterId, value);
  }
}
