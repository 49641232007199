'use strict'

const Maybe = require('folktale/maybe')

const currentUserFilterResolver = wixCodeSdk => () =>
  Maybe.fromNullable(wixCodeSdk.user.currentUser).map(
    ({ id, loggedIn }) => (loggedIn ? id : null)
  )

module.exports = currentUserFilterResolver
