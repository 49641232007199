/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface OptionsArrowProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const OptionsArrow: React.SFC<OptionsArrowProps> = ({size, ...props}) => (
  <svg viewBox="0 0 14 6" fill="currentColor" width={ size || "14" } height={ size || "6" } {...props}>
    <g id="Page-1" stroke="none" fill="none" strokeWidth="1" fillRule="evenodd">
      <g id="OPTIONS,-QUANTITY-AND-INFO-SECTION-MAPPING" transform="translate(-916 -163)" stroke="currentColor">
        <g id="Group-3-Copy" transform="translate(592 141)">
          <g id="Group-2-Copy-6">
            <path transform="rotate(-45 331 22)" d="M335 26L327 26 327 18" id="Rectangle-3-Copy-2" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
OptionsArrow.displayName = 'OptionsArrow';
/* tslint:enable */
/* eslint-enable */
