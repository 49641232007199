import React from 'react';
import {StackedInfoItem} from './InfoSectionStacked/StackedInfoItem';
import s from './StackedInfoSection.scss';
import {IProductAdditionalInfo} from '../../../../types/productDef';
import {InfoSectionProps} from '../../InfoSectionLayout';

export enum DataHook {
  StackedInfoSection = 'stacked-info-section',
}

export interface StackedInfoSectionProps extends InfoSectionProps {
  infoSectionItems: IProductAdditionalInfo[];
  shouldShowDivider: boolean;
}

export class StackedInfoSection extends React.Component<StackedInfoSectionProps> {
  public render() {
    const {infoSectionItems, shouldShowDivider} = this.props;

    return (
      <ul data-hook={DataHook.StackedInfoSection} className={s.stacked}>
        {infoSectionItems.map((infoItem, index) => {
          return (
            <StackedInfoItem
              key={infoItem.id}
              description={infoItem.description}
              title={infoItem.title}
              index={index}
              shouldShowDivider={shouldShowDivider}
            />
          );
        })}
      </ul>
    );
  }
}
