import {withSentryErrorBoundary} from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import {withStyles} from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import {CartIconApp} from './components/CartIconApp/CartIconApp';
import {CART_ICON_DSN} from './constants';

export const component = withSentryErrorBoundary(
  withStyles(CartIconApp, {cssPath: `cartIcon${!process.env.IS_MINIFIED ? '' : '.min'}.css`}),
  {
    dsn: CART_ICON_DSN,
    config: {environment: 'Native Component'},
  }
);
