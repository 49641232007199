const {
  withPropsDeserializer,
} = require('@wix/native-components-infra/dist/es/src/HOC/propsDeserializer/propsDeserializer');
const {
  withSentryErrorBoundary,
} = require('@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary');
const {GALLERY_PAGE_DSN} = require('./constants');
const {getWrappedComponent} = require('./galleryAppConfig');
const {GalleryApp} = require('./components/GalleryApp/GalleryApp');

const App = getWrappedComponent({entryName: 'gallery', Component: GalleryApp});

module.exports = {
  default: {
    component: withPropsDeserializer(
      withSentryErrorBoundary(App, {
        dsn: GALLERY_PAGE_DSN,
        config: {environment: 'Native Component'},
      })
    ),
  },
};
