import React from 'react';
import {IProduct} from '../../../types/types';
import {IProvidedGlobalProps, withGlobalProps} from '../../providers/GlobalPropsProvider';
import a11y from '@wix/wixstores-client-core/dist/es/src/assets/styles/_accessibility.scss';
import s from './ProductPrice.scss';

export enum DataHook {
  SrPriceBeforeDiscount = 'sr-product-item-price-before-discount',
  SrPriceToPay = 'sr-product-item-price-to-pay',
  OutOfStock = 'product-item-out-of-stock',
  PriceBeforeDiscount = 'product-item-price-before-discount',
  PriceToPay = 'product-item-price-to-pay',
}

export interface IProductPriceProps extends IProvidedGlobalProps {
  product: IProduct;
}

const ProductPrice: React.FunctionComponent<IProductPriceProps> = ({product, globals: {textsMap}}) => {
  const isOutOfStock = !product.isInStock;
  const shouldShowTwoPrices = product.comparePrice > 0;
  const getPriceBeforeDiscount = () => (
    <>
      <span className={a11y.srOnly} data-hook={DataHook.SrPriceBeforeDiscount}>
        {textsMap.productPriceBeforeDiscountSR}
      </span>
      <span data-hook={DataHook.PriceBeforeDiscount} className={s.priceBeforeDiscount}>
        {product.formattedPrice}
      </span>
    </>
  );

  if (isOutOfStock) {
    return (
      <span data-hook={DataHook.OutOfStock} className={s.outOfStock}>
        {textsMap.productOutOfStockText}
      </span>
    );
  } else if (product.price !== 0) {
    return (
      <div className={s.prices}>
        {shouldShowTwoPrices && getPriceBeforeDiscount()}
        {
          <>
            <span className={a11y.srOnly} data-hook={DataHook.SrPriceToPay}>
              {shouldShowTwoPrices
                ? textsMap.productPriceAfterDiscountSR
                : textsMap.productPriceWhenThereIsNoDiscountSR}
            </span>
            <span data-hook={DataHook.PriceToPay} className={s.priceToPay}>
              {shouldShowTwoPrices ? product.formattedComparePrice : product.formattedPrice}
            </span>
          </>
        }
      </div>
    );
  } else {
    return null;
  }
};

export const ProductPriceWithGlobalProps = withGlobalProps(ProductPrice);
