import React from 'react';

export interface BreadcrumbsProps {
  path: Breadcrumb[];
}

export const enum DataHooks {
  Breadcrumbs = 'breadcrumbs',
}

export interface Breadcrumb {
  name: string;
  url?: string;
}

export class Breadcrumbs extends React.Component<BreadcrumbsProps> {
  public render() {
    const {path} = this.props;

    return (
      <div data-hook={DataHooks.Breadcrumbs}>
        {path.map((link, i) => (
          <React.Fragment key={`crumb-${link.name}-${link.url}`}>
            {i > 0 ? ' / ' : ''}
            <a href={link.url} aria-disabled={!link.url}>
              {link.name}
            </a>
          </React.Fragment>
        ))}
      </div>
    );
  }
}
