'use strict'

const wixDataFunctions = ['save', 'remove', 'find', 'filter', 'query']
const wixDataSchemasFunctions = ['list', 'bulkGet']

const objectWrapper = (sourceObj, functionsToWrap, wrapperFn) => {
  const wrappedObj = Object.assign({}, sourceObj)
  functionsToWrap.forEach(fName => {
    wrappedObj[fName] = wrapperFn((...args) => sourceObj[fName](...args))
  })
  return wrappedObj
}

const wixDataFunctionWrapper = objectWrapper
const wixDataSchemaFunctionWrapper = objectWrapper

module.exports = (wixDataCodeZone, wixDataCreator) => {
  const { wixData, wixDataSchemas } = wixDataCreator()

  return {
    wixDataProxy: wixDataFunctionWrapper(
      wixData,
      wixDataFunctions,
      wixDataCodeZone
    ),
    wixDataSchemasProxy: wixDataSchemaFunctionWrapper(
      wixDataSchemas,
      wixDataSchemasFunctions,
      wixDataCodeZone
    )
  }
}
