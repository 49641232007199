import React from 'react';
import {IProvidedGlobalProps, withGlobalProps} from '../providers/GlobalPropsProvider';
import {CheckboxList} from '../CheckboxList/CheckboxList';

export interface IMultipleSelectionFilterProps extends IProvidedGlobalProps {
  options: {id: string; name: string}[];
  filterId: number;
  selectedOptionIds: string[];
}

@withGlobalProps
export class MultipleSelectionFilter extends React.Component<IMultipleSelectionFilterProps> {
  constructor(props) {
    super(props);
  }

  private handleChange(e) {
    const selectedOptionId = e.target.value;

    this.props.globals.filterProducts(this.props.filterId, selectedOptionId);
  }

  public render() {
    const {options, selectedOptionIds} = this.props;

    return (
      <CheckboxList
        id={`${this.props.filterId}`}
        selectedOptionIds={selectedOptionIds}
        options={options}
        onChange={e => this.handleChange(e)}
      />
    );
  }
}
