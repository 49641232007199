import React from 'react';
import {IProductOptionSelectionItem} from '../../../types/productDef';
import {Option} from 'wix-ui-core/dropdown-option';
import {OptionDropdownItem} from './OptionDropdownItem/OptionDropdownItem';

function toOption(o: IProductOptionSelectionItem) {
  return {
    isDisabled: o.isDisabled,
    isSelectable: !o.isDisabled,
    id: o.id,
    key: `product-dd-${o.id}`,
    value: o.value,
    description: o.description,
    linkedMediaItems: o.linkedMediaItems,
    render: () => <OptionDropdownItem label={o.description} />,
  };
}

function isVisible(o: IProductOptionSelectionItem) {
  return o.isVisible !== false;
}

export function getDropdownOptionsFromSelections(selections: IProductOptionSelectionItem[]): Option[] {
  return selections.filter(isVisible).map(toOption);
}
