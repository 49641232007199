'use strict'

const get_ = require('lodash/get')
const actionTypes = require('./actionTypes')
const DynamicPageUrlLoadState = require('./DynamicPageUrlLoadState')

const initialDynamicPagesState = {
  nextDynamicPageUrl: DynamicPageUrlLoadState.Empty(),
  previousDynamicPageUrl: DynamicPageUrlLoadState.Empty()
}

// uniqueOnClickActionsFromConnections :: (Array) -> Set
const uniqueOnClickActionsFromConnections = connections => {
  const onClickConnectedComponents = connections.filter(
    c => !!get_(c, 'config.events.onClick.action')
  )
  return new Set(
    onClickConnectedComponents.map(connection => {
      return get_(connection, 'config.events.onClick.action')
    })
  )
}

const getUrlFetchingState = connections => {
  const uniqueActions = uniqueOnClickActionsFromConnections(connections)
  const getUrlState = shouldLoad =>
    shouldLoad
      ? DynamicPageUrlLoadState.Loading()
      : DynamicPageUrlLoadState.Empty()

  return {
    nextDynamicPageUrl: getUrlState(uniqueActions.has('nextDynamicPage')),
    previousDynamicPageUrl: getUrlState(
      uniqueActions.has('previousDynamicPage')
    )
  }
}

module.exports = (state = initialDynamicPagesState, action) => {
  switch (action.type) {
    case actionTypes.INITIALIZE:
      return Object.assign({}, state, getUrlFetchingState(action.connections))

    case actionTypes.NEXT_DYNAMIC_PAGE_URL_RESULT: {
      return Object.assign({}, state, {
        nextDynamicPageUrl: DynamicPageUrlLoadState.fromUrl(action.payload)
      })
    }

    case actionTypes.PREVIOUS_DYNAMIC_PAGE_URL_RESULT: {
      return Object.assign({}, state, {
        previousDynamicPageUrl: DynamicPageUrlLoadState.fromUrl(action.payload)
      })
    }

    default:
      return state
  }
}

const selectNextDynamicPageUrl = state => state.nextDynamicPageUrl
const selectPreviousDynamicPageUrl = state => state.previousDynamicPageUrl

module.exports.selectNextDynamicPageUrl = selectNextDynamicPageUrl
module.exports.selectPreviousDynamicPageUrl = selectPreviousDynamicPageUrl
