import {HoverType, IGalleryStyleParams, ImageModeId, ImageRatioId} from '../types/types';

function getDefaultColumnsNumber(dimensions) {
  if (!dimensions) {
    return;
  }
  if (!dimensions.width) {
    return 4;
  }
  const componentWidth: number = dimensions.width;
  const productItemMinWidth: number = 250;
  const defaultColumns = Math.round(componentWidth / productItemMinWidth);
  return Math.min(defaultColumns, 4);
}

function getDefaultRowsNumber(columnsNumber: number): number {
  if (!columnsNumber) {
    return;
  }
  const LEGACY_MAX_ITEM_PER_PAGE = 20;
  const MAX_ROWS = 12;
  return Math.min(Math.floor(LEGACY_MAX_ITEM_PER_PAGE / columnsNumber), MAX_ROWS / 2);
}

export const getDefaultStyleParams = (
  shouldShowAlternativeImage = true,
  galleryColumnsFromStyleParams?,
  dimensions?
): Partial<IGalleryStyleParams> => {
  const galleryColumns = getDefaultColumnsNumber(dimensions);
  const galleryRows = getDefaultRowsNumber(galleryColumnsFromStyleParams || galleryColumns);

  return {
    booleans: {
      gallery_showProductName: true,
      gallery_showPrice: true,
      full_width: false,
      gallery_showDividers: false,
      galleryShowFilters: false,
      galleryShowSort: false,
      gallerySortNewest: true,
      gallerySortPriceAsc: true,
      gallerySortPriceDes: true,
      gallerySortNameAsc: true,
      gallerySortNameDes: true,
      showQuickView: true,
      galleryFiltersCategories: true,
      galleryFiltersPrice: true,
      galleryFiltersProductOptions: true,
    },
    numbers: {
      galleryColumns,
      galleryRows,
      galleryMargin: 10,
      gallery_imageMode: ImageModeId.Crop,
      galleryImageRatio: ImageRatioId._1x1,
      ['mobile:galleryMargin']: 10,
      ['mobile:galleryColumns']: 1,
    },
    fonts: {
      gallery_hoverType: {
        fontStyleParam: false,
        value: shouldShowAlternativeImage ? HoverType.Alternate : HoverType.None,
      },
    },
  };
};
