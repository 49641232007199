'use strict'

const identity_ = require('lodash/identity')
const mergeWith_ = require('lodash/mergeWith')
const mapValues_ = require('lodash/mapValues')
const omit_ = require('lodash/omit')
const has_ = require('lodash/has')
const Result = require('folktale/result')

const configureRaven = require('@wix/dbsm-common/src/raven/configureForWorker')
const { isLocalhost } = require('@wix/dbsm-common/src/worker/getUrl')

const getSantaVersionFromWorkerUrl = workerUrl => {
  const santaVersionMatch = workerUrl.match(/santa\/([^/]*)/)
  return santaVersionMatch ? santaVersionMatch[1] : 'unknown'
}

const tryOrGetUnknown = callback => Result.try(callback).getOrElse('unknown')
const tryOrGetError = callback =>
  Result.try(callback).matchWith({
    Ok: ({ value }) => value,
    Error: ({ value: error }) => error.message
  })

const getExtraDebugData = globalScope => ({
  referrer: tryOrGetError(() => globalScope['wix-window'].referrer),
  workerUrl: tryOrGetError(() => globalScope.location.href)
})

const getTags = globalScope => ({
  renderMode: tryOrGetUnknown(() => globalScope['wix-window'].rendering.env),
  viewMode: tryOrGetUnknown(() => globalScope['wix-window'].viewMode),
  santaVersion: tryOrGetUnknown(() =>
    getSantaVersionFromWorkerUrl(globalScope.location.href)
  )
})

const addDataFixer = raven => {
  const removeRecordsIfExists = value =>
    has_(value, 'state.records') ? omit_(value, 'state.records') : value

  const removeUserData = data => mapValues_(data, removeRecordsIfExists)

  raven.setDataCallback((data, originalCallback = identity_) => {
    mergeWith_(data.extra, (objValue, srcValue) => {
      // We customize the serializer because of this bug:
      // https://github.com/origamitower/folktale/issues/190
      if (srcValue && srcValue[Symbol.for('@@folktale:adt:tag')]) {
        return srcValue.toJSON()
      }
    })

    const finalData = originalCallback(data)
    finalData.extra = removeUserData(finalData.extra)

    return finalData
  })
}

module.exports = (Raven, globalScope, dsn, params = {}, appName) => {
  if (isLocalhost()) {
    return
  }

  configureRaven({
    Raven,
    appName,
    browserUrlGetter: () =>
      tryOrGetUnknown(() => globalScope['wix-location'].url),
    dsn,
    params
  })

  Raven.setDataCallback((data, originalCallback = identity_) => {
    data.extra = Object.assign(data.extra || {}, getExtraDebugData(globalScope))
    data.tags = Object.assign(data.tags || {}, getTags(globalScope))

    return originalCallback(data)
  })

  addDataFixer(Raven)
}
