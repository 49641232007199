/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface FancyProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const Fancy: React.SFC<FancyProps> = ({size, ...props}) => (
  <svg viewBox="0 0 16 16" fill="currentColor" width={ size || "16" } height={ size || "16" } {...props}>
    <path fill="currentColor" d="M12.19 5.257c0-2.362-1.829-4.267-4.19-4.267s-4.19 1.905-4.19 4.267v5.41h3.048v3.429c0 0.533 0.61 0.99 1.143 0.99s1.143-0.457 1.143-0.99v-3.429h3.048v-5.41z"
    />
  </svg>
);
Fancy.displayName = 'Fancy';
/* tslint:enable */
/* eslint-enable */
