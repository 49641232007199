var _a;
import { CART_ICON_DSN } from '@wix/wixstores-client-cart-icon/dist/es/src/constants';
import { PRODUCT_PAGE_DSN } from '@wix/wixstores-client-product-page/dist/es/src/constants';
import { StoresWidgetID } from '@wix/wixstores-client-core/dist/es/src/constants';
import { cartWidgetController } from '@wix/wixstores-client-cart-icon/dist/es/src/viewerScript/cartIconController';
import { createViewerScript } from '@wix/wixstores-client-core/dist/es/src/viewer-script/createViewerScript';
import { productPageController } from '@wix/wixstores-client-product-page/dist/es/src/viewerScript/productPageController';
import { galleryController } from '@wix/wixstores-client-gallery/dist/es/src/viewerScript/galleryController';
import { sliderGalleryController } from '@wix/wixstores-client-gallery/dist/es/src/viewerScript/sliderGalleryController';
import { GALLERY_PAGE_DSN } from '@wix/wixstores-client-gallery/dist/es/src/constants';
export var controllersConfig = createViewerScript((_a = {},
    _a[StoresWidgetID.CART_ICON] = {
        factory: cartWidgetController,
        sentryDSN: CART_ICON_DSN,
    },
    _a[StoresWidgetID.PRODUCT_PAGE] = {
        factory: productPageController,
        sentryDSN: PRODUCT_PAGE_DSN,
    },
    _a[StoresWidgetID.GALLERY_PAGE] = {
        factory: galleryController,
        sentryDSN: GALLERY_PAGE_DSN,
    },
    _a[StoresWidgetID.GRID_GALLERY] = {
        factory: galleryController,
        sentryDSN: GALLERY_PAGE_DSN,
    },
    _a[StoresWidgetID.SLIDER_GALLERY] = {
        factory: sliderGalleryController,
        sentryDSN: GALLERY_PAGE_DSN,
    },
    _a), { useWorkerRaven: true });
