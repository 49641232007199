'use strict'
const { combineReducers } = require('redux')

const records = require('../records/reducer')
const config = require('../dataset-config/reducer')
const dependencyResolution = require('../dependency-resolution/reducer')
const { reducer: connectedComponents } = require('../connected-components')
const dynamicPages = require('../dynamic-pages/reducer')

module.exports = combineReducers({
  records,
  config,
  dependencyResolution,
  connectedComponents,
  dynamicPages
})

const shouldAllowWixDataAccess = state =>
  config.shouldAllowWixDataAccess(state.config)
const areDependenciesResolved = state =>
  dependencyResolution.areDependenciesResolved(state.dependencyResolution)
const getCollectionName = state => config.getCollectionName(state.config)
const getConnectedComponents = state =>
  connectedComponents.getConnectedComponents(state.connectedComponents)
const getCurrentPageSize = state => records.getCurrentPageSize(state.records)
const getNumberOfPagesToShow = state =>
  records.getNumberOfPagesToShow(state.records)
const getTotalPageCount = (state, itemsCount) =>
  Math.ceil(itemsCount / getCurrentPageSize(state))
const getCurrentPageIndex = state => records.getCurrentPage(state.records)
const getFixedFilterItem = state => config.getFixedFilterItem(state.config)
const getFilter = state => config.getFilter(state.config)
const getSort = state => config.getSort(state.config)
const getIncludes = state => config.getIncludes(state.config)
const getPageSize = state => config.getPageSize(state.config)
const getPaginationData = state => records.getPaginationData(state.records)
const getPreloadData = state => config.getPreloadData(state.config)
const getCurrentPage = state => records.getCurrentPage(state.records)
const getReadWriteMode = state => config.getReadWriteMode(state.config)
const hasCurrentRecord = state => records.hasCurrentRecord(state.records)
const isDatasetConfigured = state => config.isDatasetConfigured(state.config)
const isDatasetReady = state => config.isDatasetReady(state.config)
const isDuringSave = state => records.isDuringSave(state.records)
const isForm = state => records.isForm(state.records)
const isReadOnly = state => config.isReadOnly(state.config)
const isWriteOnly = state => config.isWriteOnly(state.config)
const selectCurrentRecord = state => records.selectCurrentRecord(state.records)
const selectCurrentRecordIndex = state =>
  records.selectCurrentRecordIndex(state.records)
const selectDefaultDraft = state => records.selectDefaultDraft(state.records)
const selectDesiredRecordIndex = state =>
  records.selectDesiredRecordIndex(state.records)
const selectFieldsToUpdate = state =>
  records.selectFieldsToUpdate(state.records)
const selectLastSavedRecord = state =>
  records.selectLastSavedRecord(state.records)
const selectUpdateSource = state => records.selectUpdateSource(state.records)
const selectNewRecordIndex = state =>
  records.selectNewRecordIndex(state.records)
const selectNextDynamicPageUrl = state =>
  dynamicPages.selectNextDynamicPageUrl(state.dynamicPages)
const selectPreviousDynamicPageUrl = state =>
  dynamicPages.selectPreviousDynamicPageUrl(state.dynamicPages)
const selectRefreshController = state =>
  records.selectRefreshController(state.records)
const selectRefreshCurrentRecord = state =>
  records.selectRefreshCurrentRecord(state.records)
const selectRefreshCurrentView = state =>
  records.selectRefreshCurrentView(state.records)
const selectRemoveCurrentRecord = state =>
  records.selectRemoveCurrentRecord(state.records)
const selectRevertChanges = state => records.selectRevertChanges(state.records)
const selectSaveRecord = state => records.selectSaveRecord(state.records)

module.exports.shouldAllowWixDataAccess = shouldAllowWixDataAccess
module.exports.areDependenciesResolved = areDependenciesResolved
module.exports.getCollectionName = getCollectionName
module.exports.getConnectedComponents = getConnectedComponents
module.exports.getCurrentPageSize = getCurrentPageSize
module.exports.getNumberOfPagesToShow = getNumberOfPagesToShow
module.exports.getTotalPageCount = getTotalPageCount
module.exports.getCurrentPageIndex = getCurrentPageIndex
module.exports.getFixedFilterItem = getFixedFilterItem
module.exports.getFilter = getFilter
module.exports.getSort = getSort
module.exports.getIncludes = getIncludes
module.exports.getPageSize = getPageSize
module.exports.getPaginationData = getPaginationData
module.exports.getPreloadData = getPreloadData
module.exports.getCurrentPage = getCurrentPage
module.exports.getReadWriteMode = getReadWriteMode
module.exports.hasCurrentRecord = hasCurrentRecord
module.exports.isDatasetConfigured = isDatasetConfigured
module.exports.isDatasetReady = isDatasetReady
module.exports.isDuringSave = isDuringSave
module.exports.isForm = isForm
module.exports.isReadOnly = isReadOnly
module.exports.isWriteOnly = isWriteOnly
module.exports.selectCurrentRecord = selectCurrentRecord
module.exports.selectCurrentRecordIndex = selectCurrentRecordIndex
module.exports.selectDefaultDraft = selectDefaultDraft
module.exports.selectDesiredRecordIndex = selectDesiredRecordIndex
module.exports.selectFieldsToUpdate = selectFieldsToUpdate
module.exports.selectLastSavedRecord = selectLastSavedRecord
module.exports.selectUpdateSource = selectUpdateSource
module.exports.selectNewRecordIndex = selectNewRecordIndex
module.exports.selectNextDynamicPageUrl = selectNextDynamicPageUrl
module.exports.selectPreviousDynamicPageUrl = selectPreviousDynamicPageUrl
module.exports.selectRefreshController = selectRefreshController
module.exports.selectRefreshCurrentRecord = selectRefreshCurrentRecord
module.exports.selectRefreshCurrentView = selectRefreshCurrentView
module.exports.selectRemoveCurrentRecord = selectRemoveCurrentRecord
module.exports.selectRevertChanges = selectRevertChanges
module.exports.selectSaveRecord = selectSaveRecord
