import React from 'react';
import s from './InfoSectionTitle.scss';
import {InfoSectionLayoutId} from '../../../constants';
import classNames from 'classnames';

export enum DataHook {
  Title = 'info-section-title',
}

export interface InfoSectionTitleProps {
  title: string;
  theme: InfoSectionLayoutId;
}

export class InfoSectionTitle extends React.Component<InfoSectionTitleProps> {
  public render() {
    const {theme, title} = this.props;
    const titleClassName = classNames(
      s.general,
      {[s.collapse]: theme === InfoSectionLayoutId.Collapse},
      {[s.stacked]: theme === InfoSectionLayoutId.Stacked},
      {[s.tabs]: theme === InfoSectionLayoutId.Tabs},
      {[s.columns]: theme === InfoSectionLayoutId.Columns}
    );
    return (
      <h3 className={titleClassName} data-hook={DataHook.Title}>
        {title}
      </h3>
    );
  }
}
