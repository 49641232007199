import React from 'react';
import FontFaceObserver from 'fontfaceobserver';
import _ from 'lodash';

export interface IconLoaderProps {
  fontFamily: IFontFamily;
  shouldWaitForFont?: boolean;
  isControllerLoaded: boolean;
}

export interface IFontFamily {
  family?: string;
  cssFontFamily?: string;
}

export interface IconLoaderState {
  isFontLoaded: boolean;
}

function stripQuotes(str) {
  return str.replace(/^["']|["']$/g, '');
}

export class IconLoader extends React.Component<IconLoaderProps, IconLoaderState> {
  public static defaultProps = {
    shouldWaitForFont: true,
  };

  constructor(props) {
    super(props);
    if (this.props.shouldWaitForFont) {
      this.state = {
        isFontLoaded: !props.fontFamily,
      };
    } else {
      this.state = {
        isFontLoaded: true,
      };
    }
  }

  public componentDidMount() {
    this.loadFonts();
  }

  public componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.fontFamily, this.props.fontFamily)) {
      this.setState({isFontLoaded: false}, () => {
        this.loadFonts();
      });
    }
  }

  private getFontFamilies(): string[] {
    const {fontFamily} = this.props;
    return (fontFamily.cssFontFamily || fontFamily.family || '')
      .split(',')
      .filter(Boolean)
      .map(stripQuotes);
  }

  private loadFonts() {
    if (this.props.fontFamily) {
      const promises = this.getFontFamilies().map(font => new FontFaceObserver(font).load(null, 500));
      Promise.all(promises)
        .then(() => this.markAsLoaded())
        .catch(() => this.markAsLoaded());
    }
  }

  private markAsLoaded() {
    setTimeout(() => {
      this.setState({isFontLoaded: true});
    }, 0);
  }

  public render() {
    return this.state.isFontLoaded && this.props.isControllerLoaded ? React.Children.only(this.props.children) : null;
  }
}
