var _this = this;
import * as tslib_1 from "tslib";
import { addressesAppDefId, ecomAppDefID, membersAppDefId, myAddressesPageId, myWalletAppDefId, myWalletPageId, orderHistoryPageId, } from './constants';
import { Experiments } from '../common/experiments/Experiments';
import { createStoreFrontBILogger } from '@wix/wixstores-client-core/dist/src/bi/configure-front-bi-logger';
var appToken;
var options;
var biLogger;
var sdk;
var experiments;
function addOrders() {
    var membersAPI = sdk.application.getPublicAPI(appToken, { appDefinitionId: membersAppDefId });
    return membersAPI.addSection({
        appDefinitionId: ecomAppDefID,
        pageId: orderHistoryPageId,
        social: false,
        showInLoginMenu: true,
    });
}
function addCheckout() {
    return sdk.tpa.isAppSectionInstalled(appToken, { sectionId: 'checkout' }).then(function (isInstalled) {
        if (!isInstalled) {
            return sdk.tpa.add.component(appToken, {
                appDefinitionId: ecomAppDefID,
                componentType: 'PAGE',
                page: {
                    pageId: 'checkout',
                    shouldNavigate: false,
                },
            });
        }
    });
}
function installMembers() {
    return sdk.application.install(appToken, { appDefinitionId: membersAppDefId, initiatorAppDefinitionId: ecomAppDefID });
}
function installAddresses() {
    return new Promise(function (resolve, reject) {
        if (experiments.enabled('specs.stores.ExpressCheckout')) {
            sdk.tpa
                .isApplicationInstalled(appToken, { appDefinitionId: addressesAppDefId })
                .then(function (isInstalled) {
                if (!isInstalled) {
                    // Temp workaround, till membersAPI will allow to add main section of installed APP.
                    var membersAPI = sdk.application.getPublicAPI(appToken, { appDefinitionId: membersAppDefId });
                    return membersAPI.addApplication({
                        appDefinitionId: addressesAppDefId,
                        pageId: myAddressesPageId,
                        social: false,
                        showInLoginMenu: true,
                    });
                    // return sdk.tpa.add.application(appToken, {appDefinitionId: addressesAppDefId});
                }
            })
                .then(resolve)
                .catch(reject);
        }
        else {
            resolve();
        }
    });
}
function installMyWallet() {
    return new Promise(function (resolve, reject) {
        if (experiments.enabled('specs.stores.ExpressCheckout') && experiments.enabled('specs.cashier.SaveCreditCard')) {
            sdk.tpa
                .isApplicationInstalled(appToken, { appDefinitionId: myWalletAppDefId })
                .then(function (isInstalled) {
                if (!isInstalled) {
                    // Temp workaround, till membersAPI will allow to add main section of installed APP.
                    var membersAPI = sdk.application.getPublicAPI(appToken, { appDefinitionId: membersAppDefId });
                    return membersAPI.addApplication({
                        appDefinitionId: myWalletAppDefId,
                        pageId: myWalletPageId,
                        social: false,
                        showInLoginMenu: true,
                    });
                }
            })
                .then(resolve)
                .catch(reject);
        }
        else {
            resolve();
        }
    });
}
function getExperiments(instance) {
    return tslib_1.__awaiter(this, void 0, Promise, function () {
        var experimentsResponse;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch('/_api/wix-ecommerce-storefront-web/api', {
                        method: 'post',
                        body: JSON.stringify({
                            query: require('!raw-loader!../common/experiments/getConfig.graphql'),
                            source: 'WixStoresWebClient',
                            operationName: 'getConfig',
                        }),
                        headers: {
                            'X-ecom-instance': instance,
                            Authorization: instance,
                            'Content-Type': 'application/json; charset=utf-8',
                        },
                    })
                        .then(function (data) { return data.json(); })
                        .then(function (data) {
                        return (data.data.experiments || []).reduce(function (acc, e) {
                            acc[e.name] = e.value;
                            return acc;
                        }, {});
                    })];
                case 1:
                    experimentsResponse = _a.sent();
                    return [2 /*return*/, new Experiments(experimentsResponse)];
            }
        });
    });
}
function isMembersInstalled() {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            return [2 /*return*/, sdk.tpa.isAppSectionInstalled(appToken, { sectionId: orderHistoryPageId })];
        });
    });
}
export var editorScript = {
    editorReady: function (_editorSDK, _appToken, _options) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        var instance, encodedInstance, parsedInstance, storeId, isMerchant;
        var _this = this;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    options = _options;
                    appToken = _appToken;
                    sdk = _editorSDK;
                    return [4 /*yield*/, sdk.document.info.getAppInstance('token')];
                case 1:
                    instance = _a.sent();
                    encodedInstance = instance.substring(instance.indexOf('.') + 1);
                    parsedInstance = JSON.parse(atob(encodedInstance));
                    storeId = parsedInstance.instanceId;
                    isMerchant = true;
                    biLogger = createStoreFrontBILogger({ uuid: parsedInstance.uid }, parsedInstance.biToken, {
                        storeId: storeId,
                        isMerchant: isMerchant,
                        appName: 'wixstores worker',
                    });
                    return [4 /*yield*/, getExperiments(instance)];
                case 2:
                    experiments = _a.sent();
                    return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var membersInstalled, addressesInstalled, myWalletInstalled;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!experiments.enabled('specs.stores.CheckoutInstalledOnEditorReady')) return [3 /*break*/, 2];
                                        return [4 /*yield*/, addCheckout()];
                                    case 1:
                                        _a.sent();
                                        _a.label = 2;
                                    case 2:
                                        if (!(options && options.firstInstall)) return [3 /*break*/, 3];
                                        if (options.origin &&
                                            options.origin.type === 'ADI' &&
                                            experiments.enabled('specs.stores.SkipInstallMembersAreaInADI')) {
                                            return [2 /*return*/, resolve()];
                                        }
                                        else {
                                            return [2 /*return*/, installMembers().then(resolve, reject)];
                                        }
                                        return [3 /*break*/, 12];
                                    case 3: return [4 /*yield*/, isMembersInstalled()];
                                    case 4:
                                        membersInstalled = _a.sent();
                                        if (membersInstalled) {
                                            //tslint:disable-next-line:no-floating-promises
                                            biLogger.exposureEventForTests({
                                                type: 'members_is_installed',
                                                testName: 'express_checkout',
                                                is_eligible: true,
                                            });
                                        }
                                        else {
                                            //tslint:disable-next-line:no-floating-promises
                                            biLogger.exposureEventForTests({
                                                testName: 'express_checkout',
                                                is_eligible: false,
                                            });
                                        }
                                        if (!experiments.enabled('specs.stores.ExpressCheckout')) return [3 /*break*/, 11];
                                        return [4 /*yield*/, sdk.tpa.isApplicationInstalled(appToken, {
                                                appDefinitionId: addressesAppDefId,
                                            })];
                                    case 5:
                                        addressesInstalled = _a.sent();
                                        return [4 /*yield*/, sdk.tpa.isApplicationInstalled(appToken, { appDefinitionId: myWalletAppDefId })];
                                    case 6:
                                        myWalletInstalled = _a.sent();
                                        if (!((!addressesInstalled || !myWalletInstalled) && membersInstalled)) return [3 /*break*/, 11];
                                        if (!experiments.enabled('specs.cashier.SaveCreditCard')) return [3 /*break*/, 8];
                                        return [4 /*yield*/, installMyWallet()];
                                    case 7:
                                        _a.sent();
                                        _a.label = 8;
                                    case 8: return [4 /*yield*/, installAddresses()];
                                    case 9:
                                        _a.sent();
                                        return [4 /*yield*/, sdk.document.save()];
                                    case 10:
                                        _a.sent();
                                        _a.label = 11;
                                    case 11:
                                        resolve();
                                        _a.label = 12;
                                    case 12: return [2 /*return*/];
                                }
                            });
                        }); })];
            }
        });
    }); },
    getAppManifest: function () { return ({}); },
    onEvent: function () {
        /**/
    },
    handleAction: function (_a) {
        var type = _a.type, payload = _a.payload;
        try {
            switch (type) {
                case 'appInstalled':
                    switch (payload.appDefinitionId) {
                        case membersAppDefId: {
                            if (options.firstInstall) {
                                //tslint:disable-next-line:no-floating-promises
                                biLogger.exposureEventForTests({
                                    type: 'members_added_by_stores',
                                    testName: 'express_checkout',
                                    is_eligible: true,
                                });
                            }
                            else {
                                //tslint:disable-next-line:no-floating-promises
                                biLogger.exposureEventForTests({
                                    type: 'members_added_by_user',
                                    testName: 'express_checkout',
                                    is_eligible: true,
                                });
                            }
                            return Promise.all([addOrders(), addCheckout(), installAddresses(), installMyWallet()]);
                        }
                        default:
                            return Promise.resolve();
                    }
                default:
                    return Promise.resolve();
            }
        }
        catch (e) {
            return Promise.reject(e);
        }
    },
    getControllerPresets: function () { return Promise.resolve([]); },
};
