import React from 'react';
import s from './ColorFilter.scss';
import {IProvidedGlobalProps, withGlobalProps} from '../providers/GlobalPropsProvider';
import {IColorOption} from '../../types/types';
import {
  IColorSampleGroupOption,
  ColorSampleGroup,
} from '@wix/wixstores-client-common-components/dist/es/src/ColorSampleGroup/ColorSampleGroup';
import autobind from 'autobind-decorator';
import _ from 'lodash';

export interface IColorFilterProps extends IProvidedGlobalProps {
  colorOptions: IColorOption[];
  showOptionName?: Function;
  filterId: number;
  selected: string[];
}

@withGlobalProps
export class ColorFilter extends React.Component<IColorFilterProps> {
  public render() {
    const {colorOptions, selected: controllerSelected} = this.props;

    const options: IColorSampleGroupOption[] = colorOptions.map((option, i) => ({
      id: i,
      value: option.rgbValue,
      description: option.name,
    }));

    const selected = options.filter(option => controllerSelected.indexOf(option.value) > -1);

    return (
      <div className={s.colorList}>
        <ColorSampleGroup
          selected={selected as any}
          options={options}
          onMouseLeave={() => this.props.showOptionName('')}
          onMouseEnter={value => this.props.showOptionName(value)}
          onSelect={this.handleClick}
          allowMultiple={true}
        />
      </div>
    );
  }

  @autobind
  private handleClick(selections: IColorSampleGroupOption[]) {
    const selectedValue = _.xor(selections.map(option => option.value), this.props.selected);
    this.props.globals.filterProducts(this.props.filterId, selectedValue[0]);
  }
}
