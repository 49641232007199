const getDefaults = (initAppForPageBIParams = {}, viewMode) => {
  const liveSiteDefaults =
    viewMode !== 'Preview'
      ? { vsi: initAppForPageBIParams.viewerSessionId }
      : {}

  return Object.assign(
    {},
    {
      msid: initAppForPageBIParams.metaSiteId,
      visitorId: initAppForPageBIParams.visitorId,
      pageId: initAppForPageBIParams.pageId
    },
    liveSiteDefaults
  )
}

module.exports = getDefaults
