import React from 'react';
import s from './TextOption.scss';
import classNames from 'classnames';

export enum DataHook {
  TextOption = 'text-option',
  Title = 'text-option-title',
  TextArea = 'text-option-text-area',
  RemainLength = 'text-option-remain-length',
}

export interface TextOptionProps {
  handleOnChange(textAreaElemValue: string): void;
  isRequired: boolean;
  maxLength: number;
  title: string;
  value: string;
}

export interface TextOptionState {
  isFocused: boolean;
  remainCount: number;
  value: string;
}

export class TextOption extends React.Component<TextOptionProps, TextOptionState> {
  private readonly textAreaRef: React.RefObject<HTMLTextAreaElement> = React.createRef();

  public state = {value: this.props.value, remainCount: this.props.maxLength, isFocused: false};

  private readonly onFocus = () => {
    this.focusTextArea();
    this.setState({isFocused: true});
  };

  private readonly focusTextArea = () => {
    this.textAreaRef.current.focus();
  };

  private readonly onInputChange = event => {
    const textAreaElemValue: string = event.target.value;

    this.setState({
      value: textAreaElemValue,
      remainCount: this.props.maxLength - textAreaElemValue.length,
    });

    this.props.handleOnChange(textAreaElemValue);
  };

  public render() {
    const {title, isRequired, maxLength} = this.props;
    const {remainCount, value, isFocused} = this.state;

    const textAreaClasses = classNames(
      s.textOptionTextArea,
      {[s.midHeight]: isFocused && (maxLength > 25 && maxLength < 250)},
      {[s.fullHeight]: isFocused && maxLength > 250}
    );

    return (
      <div data-hook={DataHook.TextOption} className={s.textOption}>
        <label data-hook={DataHook.Title} className={s.textOptionInputLabel}>
          {`${title}${!isRequired ? ' (optional)' : ''}`}
        </label>
        <div className={s.textAreaContainer}>
          <textarea
            data-hook={DataHook.TextArea}
            ref={this.textAreaRef}
            maxLength={maxLength}
            value={value}
            className={textAreaClasses}
            onFocus={this.onFocus}
            onChange={this.onInputChange}
          />
          <span aria-hidden="true" className={s.remainCount} data-hook={DataHook.RemainLength}>
            {remainCount}
          </span>
        </div>
      </div>
    );
  }
}
