'use strict'

const flow_ = require('lodash/flow')
const isPlainObject_ = require('lodash/isPlainObject')
const curry_ = require('lodash/curry')
const mapValues_ = require('lodash/mapValues')

const QueryResults = require('../helpers/queryResults')

const { assign, bind } = require('./utils')

const {
  cleanseRecord,
  createDraft,
  getRecordId,
  markRecordDirty,
  mergeRecord
} = require('./records')

const freshCollection = () => ({
  records: {},
  drafts: {},
  scopes: {}
})

const insertToObjectMap = (accessor, idFn, items) => object =>
  Object.assign(
    {},
    accessor(object),
    ...items.map(item => ({ [idFn(item)]: item }))
  )

const updateInObjectMap = (accessor, id, updateFn) => object =>
  Object.assign({}, accessor(object), { [id]: updateFn(accessor(object)[id]) })

const removeFromObjectMap = (accessor, id) => object =>
  Object.assign(
    {},
    ...Object.keys(accessor(object))
      .filter(mapId => mapId !== id)
      .map(mapId => ({ [mapId]: accessor(object)[mapId] }))
  )

const setCollectionRecords = records => assign({ records })

const setDrafts = drafts => assign({ drafts })

const setScope = curry_((key, scope, collection) =>
  assign({
    scopes: Object.assign({}, collection.scopes, { [key]: scope })
  })(collection)
)

const getScope = scopeKey => collection => collection.scopes[scopeKey]

const iterateScopes = (iterateFn, predicate, collection) =>
  Object.keys(collection.scopes)
    .filter(key => predicate(collection.scopes[key], key))
    .map(key => iterateFn(collection.scopes[key], key))

const insertRecords = records =>
  bind(
    insertToObjectMap(({ records }) => records, getRecordId, records),
    setCollectionRecords
  )

const insertRecord = record => insertRecords([record])

const insertDrafts = drafts =>
  bind(
    insertToObjectMap(({ drafts }) => drafts, getRecordId, drafts),
    setDrafts
  )

const insertDraft = draft => insertDrafts([draft])

const removeRecord = recordId =>
  bind(
    removeFromObjectMap(({ records }) => records, recordId),
    setCollectionRecords
  )

const removeDraft = draft =>
  bind(
    removeFromObjectMap(({ drafts }) => drafts, getRecordId(draft)),
    setDrafts
  )

const resetDraft = (draft, defaultDraft) =>
  bind(
    updateInObjectMap(({ drafts }) => drafts, getRecordId(draft), d =>
      createDraft(defaultDraft, d._id)
    ),
    setDrafts
  )

const clearDrafts = () => setDrafts({})

const updateScope = (scopeKey, updateFn) =>
  bind(flow_(getScope(scopeKey), updateFn), setScope(scopeKey))

const storeQueryResults = queryResult =>
  queryResult.matchWith({
    Empty: () => _ => _,
    Results: ({ items, totalCount, offset }) =>
      items.length > 0 ? insertRecords(items) : _ => _
  })

const updateRecordFields = (recordId, fieldValues) =>
  bind(
    updateInObjectMap(({ drafts }) => drafts, recordId, draft =>
      Object.assign({}, markRecordDirty(draft), fieldValues)
    ),
    setDrafts
  )

const doesRecordExist = (id, collection) => !!collection.records[id]

const getDraftOrRecord = (id, collection) => {
  if (isPlainObject_(collection.records[id])) {
    const mergedRecord = mergeRecord(
      collection.records[id],
      collection.drafts[id]
    )

    return mergedRecord
  } else {
    return isPlainObject_(collection.drafts[id])
      ? mergeRecord(collection.drafts[id])
      : null
  }
}

const readFromCollection = (
  scopeKey,
  fromIndex,
  toIndex,
  collection,
  allowMissing = false
) => {
  const scope = getScope(scopeKey)(collection)
  const numRequestedRecords = toIndex - fromIndex
  const requestedRecordsSlice = scope.records.slice(fromIndex, toIndex)
  const matchingRecords = requestedRecordsSlice.reduce((acc, id) => {
    const record = getDraftOrRecord(id, collection)
    return record != null ? acc.concat(cleanseRecord(record)) : acc
  }, [])

  return QueryResults.of({
    items: matchingRecords,
    totalCount: scope.numMatchingRecords || 0,
    offset: fromIndex
  }).filter(hasRequestedMatchingItems)

  function hasRequestedMatchingItems({ items }) {
    return allowMissing || items.length >= numRequestedRecords
  }
}

const fromWarmupCollection = collection => {
  const fromWarmupScope = ({
    records,
    numMatchingRecords,
    numSeedRecords,
    newRecordMarkers
  }) => {
    const recordsWithoutDrafts = records.filter(
      (_, i) => !newRecordMarkers.includes(i)
    )

    return {
      records: recordsWithoutDrafts,
      numMatchingRecords: numMatchingRecords - newRecordMarkers.length,
      numSeedRecords: recordsWithoutDrafts.length,
      newRecordMarkers: []
    }
  }

  return {
    records: collection.records,
    drafts: {},
    scopes: mapValues_(collection.scopes, fromWarmupScope)
  }
}

module.exports.clearDrafts = clearDrafts
module.exports.doesRecordExist = doesRecordExist
module.exports.freshCollection = freshCollection
module.exports.fromWarmupCollection = fromWarmupCollection
module.exports.getDraftOrRecord = getDraftOrRecord
module.exports.getScope = getScope
module.exports.insertDraft = insertDraft
module.exports.insertRecord = insertRecord
module.exports.iterateScopes = curry_(iterateScopes)
module.exports.readFromCollection = curry_(readFromCollection)
module.exports.removeDraft = removeDraft
module.exports.removeRecord = removeRecord
module.exports.resetDraft = resetDraft
module.exports.setScope = setScope
module.exports.storeQueryResults = storeQueryResults
module.exports.updateRecordFields = updateRecordFields
module.exports.updateScope = curry_(updateScope)
