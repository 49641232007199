'use strict'

const unset_ = require('lodash/unset')
const get_ = require('lodash/get')

const controllerStore = logger => {
  const scopesMap = {}
  return {
    setController: ({ componentId, itemId }, controller) => {
      logger.breadcrumb({
        level: 'info',
        category: 'scopeStore',
        message: 'adding scope',
        data: { componentId, itemId }
      })
      scopesMap[componentId] = scopesMap[componentId] || {}
      scopesMap[componentId][itemId] = controller
    },
    getController: ({ componentId, itemId }) => {
      const componentScopes = scopesMap[componentId]
      return componentScopes && componentScopes[itemId]
    },
    removeController: ({ componentId, itemId }) => {
      logger.breadcrumb({
        level: 'info',
        category: 'scopeStore',
        message: 'removing scope',
        data: { componentId, itemId }
      })
      const componentScopes = scopesMap[componentId]
      if (get_(componentScopes, itemId)) {
        componentScopes[itemId].dispose()
        unset_(componentScopes, itemId)
      }
    },
    getAll: () => {
      return Object.values(scopesMap).reduce(
        (acc, curr) => acc.concat(Object.values(curr)),
        []
      )
    }
  }
}

module.exports = controllerStore
