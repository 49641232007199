'use strict'
const forEach_ = require('lodash/forEach')
const has_ = require('lodash/has')
const isEmpty_ = require('lodash/isEmpty')

const convertValueToString = require('../../helpers/convertValueToString')

const getFieldValue = require('../../helpers/getFieldValue')
const formatValue = require('../../helpers/formatValue')
const {
  selectCurrentRecord,
  selectCurrentRecordIndex
} = require('../../dataset-controller/rootReducer')
const addComponentEventListener = require('../addComponentEventListener')

module.exports = ({
  getState,
  wixSdk,
  getFieldType,
  applicationCodeZone,
  databindingVerboseReporter
}) => {
  const copyRecordToImage = (image, record, properties) =>
    forEach_(
      properties || [],
      ({ fieldName, format }, propName) =>
        (image[propName] = convertValueToString(
          formatValue(getFieldValue(record, fieldName), {
            format,
            wixSdk,
            fieldType: getFieldType(fieldName).getOrElse('')
          })
        ))
    )

  const setCurrentIndexOfGallery = component => {
    try {
      if (component.galleryCapabilities['hasCurrentItem']) {
        component.currentIndex = selectCurrentRecordIndex(getState())
      }
    } catch (e) {
      // see comments in CLNT-7339, we will use a new API that will allow us to know if we can set the current index
    }
  }

  const refreshView = async (
    { connectionConfig: { properties }, component },
    actions
  ) => {
    const { items: records } = await actions.fetchCurrentItems(getState())

    try {
      const items = records.map(record => {
        const image = {}
        copyRecordToImage(image, record, properties)
        return image
      })
      databindingVerboseReporter.logValue({
        component,
        valueDescription: items
      })
      component.items = items
    } catch (e) {
      // is the value bound to the src property bad?
      if (e.name !== 'URIError') {
        throw e
      }
    }

    setCurrentIndexOfGallery(component)
  }

  const logVerboseForBinding = (component, properties) => {
    const bindingDescription = {}

    forEach_(properties, ({ fieldName }, propName) => {
      bindingDescription[propName] = fieldName
    })

    databindingVerboseReporter.logBinding({
      component,
      bindingDescription
    })
  }

  return {
    isValidContext({ connectionConfig: { properties } }) {
      return !isEmpty_(properties)
    },

    bindToComponent(
      {
        connectionConfig: { properties },
        component
      },
      actions
    ) {
      if (has_(properties, 'link')) {
        //set gallery settings to "when clicked --> a link opens"
        component.clickAction = 'link'
      }

      if (component.galleryCapabilities['hasCurrentItem']) {
        addComponentEventListener(
          component,
          'onCurrentItemChanged',
          () => {
            actions.setCurrentIndex(component.currentIndex)
          },
          applicationCodeZone
        )
      }

      logVerboseForBinding(component, properties)
    },

    currentRecordModified(
      {
        connectionConfig: { properties },
        component
      },
      actions,
      updatedFields
    ) {
      const record = selectCurrentRecord(getState())
      const currentIndex = selectCurrentRecordIndex(getState())

      const imagesToBeChanged = component.items || []
      const imageToBeChanged = imagesToBeChanged[currentIndex]

      if (imageToBeChanged) {
        copyRecordToImage(imageToBeChanged, record, properties)
      }

      component.items = imagesToBeChanged
      setCurrentIndexOfGallery(component)
    },

    recordSetLoaded: refreshView,

    currentViewChanged: refreshView,

    currentIndexChanged({ component }, actions) {
      setCurrentIndexOfGallery(component)
    }
  }
}
