import React from 'react';
import s from './EmptyGallery.scss';
import {IProvidedGlobalProps, withGlobalProps} from '../../providers/GlobalPropsProvider';
import classNames from 'classnames';

export interface EmptyGalleryProps extends IProvidedGlobalProps {
  hasFilters: boolean;
}

@withGlobalProps
export class EmptyGallery extends React.Component<EmptyGalleryProps> {
  public render() {
    const {hasFilters} = this.props;
    return (
      <header data-hook="empty-gallery" className={classNames(s.emptyGallery, {[s.filterEmptyState]: hasFilters})}>
        <h2 className={s.titleSub}>
          {hasFilters
            ? this.props.globals.textsMap.noProductsFilteredMessageText
            : this.props.globals.textsMap.noProductsMessageText}
        </h2>
      </header>
    );
  }
}
