'use strict'

const cloneDeep_ = require('lodash/cloneDeep')
const noop_ = require('lodash/noop')

const createControllerStore = require('../dataset-controller/controllerStore')
const initCreateDataset = require('../dataset-controller/controller')

const addScopeToAllHandshakes = (handshakes, { componentId, itemId }) => {
  if (handshakes) {
    return handshakes.map(handshake =>
      Object.assign({}, handshake, {
        controllerApi: handshake.controllerApi.inScope(componentId, itemId)
      })
    )
  }
}

const createScopedDatasetId = (datasetId, scopeId) =>
  [
    datasetId,
    'componentId',
    scopeId.componentId,
    'itemId',
    scopeId.itemId
  ].join('_')

const controllerFactory = (logger, datasetParams) => {
  const controllerStore = createControllerStore(logger)
  const factory = {
    createPrimaryController: () => primaryController,

    createDetailsController: scopeId =>
      createDataset(
        true,
        false,
        Object.assign({}, datasetParams, {
          firePlatformEvent: noop_,
          datasetId: createScopedDatasetId(datasetParams.datasetId, scopeId),
          handshakes: addScopeToAllHandshakes(datasetParams.handshakes, scopeId)
        })
      ),

    createFixedItemController: (scopeId, fixedItem, parentId) => {
      const { dynamicPagesData } = datasetParams.routerData || {}
      const newControllerConfig = cloneDeep_(datasetParams.controllerConfig)
      newControllerConfig.dataset.filter = datasetParams.wixDataProxy
        .filter()
        .eq('_id', fixedItem._id)
        ._build()

      return createDataset(
        true,
        true,
        Object.assign({}, datasetParams, {
          controllerConfig: newControllerConfig,
          firePlatformEvent: noop_,
          routerData: {
            dynamicPagesData,
            prefetchedData: { items: [fixedItem], totalCount: 1 }
          },
          datasetId: createScopedDatasetId(datasetParams.datasetId, scopeId),
          parentId
        })
      )
    }
  }
  const createDataset = initCreateDataset(factory, controllerStore)
  const primaryController = createDataset(false, false, datasetParams)

  return factory
}

module.exports = controllerFactory
