import {IGalleryStyleParams} from './types/types';
import {getDefaultStyleParams} from './viewerScript/getDefaultStyleParams';

export function getStyleParamsWithDefaultsFunc({style: {styleParams}, dimensions}) {
  ['galleryColumns', 'galleryRows', 'galleryMargin'].forEach(key => {
    if (styleParams.numbers[key] !== undefined) {
      styleParams.numbers[key] = Math.round(styleParams.numbers[key]);
    }
  });
  const {
    booleans: {showAlternativeImage},
    numbers: {galleryColumns},
  } = styleParams as IGalleryStyleParams;

  return {defaults: getDefaultStyleParams(showAlternativeImage, galleryColumns, dimensions), overrides: styleParams};
}
