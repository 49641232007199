import React from 'react';
import {Popover} from 'wix-ui-core/popover';
import st from './ErrorTooltip.st.css';
import {ErrorTooltipPlacement} from '../../../constants';
import cx from 'classnames';

export interface ErrorTooltipProps {
  placement?: ErrorTooltipPlacement;
  message: string;
}

export class ErrorTooltip extends React.Component<ErrorTooltipProps> {
  public static defaultProps = {
    placement: ErrorTooltipPlacement.Left,
  };

  public render() {
    const {message, placement} = this.props;

    const classNames = cx({...st(placement)}.className, {...st('error')}.className);

    return (
      <Popover className={classNames} placement={placement} shown={true} showArrow={true}>
        <Popover.Content>
          <span data-hook="error-message">{message}</span>
        </Popover.Content>
      </Popover>
    );
  }
}
