import React from 'react';
import classNames from 'classnames';
import {IconRebuilder} from '../IconRebuilder/IconRebuilder';
import {SvgIcon} from '../SvgIcon/SvgIcon';

export interface IconProps {
  count: number;
  s: {[key: string]: string};
  iconId: number;
  text?: string;
  resizeComponent(width: number): Promise<void>;
  containerHeight: number;
  aspectRatio?: number;
}

export const Icon1 = (props: IconProps) => (
  <SvgIcon viewBox="5.7 0 105.5 126.1" preserveAspectRatio={true} {...props}>
    <path d="M99.8 28.4c0-1.2-0.9-2-2.1-2h-15c0 3.2 0 7.6 0 8.2 0 1.5-1.2 2.6-2.6 2.9 -1.5 0.3-2.9-0.9-3.2-2.3 0-0.3 0-0.3 0-0.6 0-0.9 0-4.7 0-8.2H40.1c0 3.2 0 7.3 0 8.2 0 1.5-1.2 2.9-2.6 2.9 -1.5 0-2.9-0.9-3.2-2.3 0-0.3 0-0.3 0-0.6 0-0.6 0-5 0-8.2h-15c-1.2 0-2 0.9-2 2L8.3 124c0 1.2 0.9 2.1 2.1 2.1h96.3c1.2 0 2.1-0.9 2.1-2.1L99.8 28.4z" />
    <path d="M59.1 5.9c-2.9 0-2 0-2.9 0 -2 0-4.4 0.6-6.4 1.5 -3.2 1.5-5.9 4.1-7.6 7.3 -0.9 1.8-1.5 3.5-1.8 5.6 0 0.9-0.3 1.5-0.3 2.3 0 1.2 0 2.1 0 3.2 0 1.5-1.2 2.9-2.6 2.9 -1.5 0-2.9-0.9-3.2-2.3 0-0.3 0-0.3 0-0.6 0-1.2 0-2.3 0-3.5 0-3.2 0.9-6.4 2-9.4 1.2-2.3 2.6-4.7 4.7-6.4 3.2-2.9 6.7-5 11.1-5.9C53.5 0.3 55 0 56.7 0c1.5 0 2.9 0 4.4 0 2.9 0 5.6 0.6 7.9 1.8 2.6 1.2 5 2.6 6.7 4.4 3.2 3.2 5.3 6.7 6.4 11.1 0.3 1.5 0.6 3.2 0.6 4.7 0 1.2 0 2.3 0 3.2 0 1.5-1.2 2.6-2.6 2.9s-2.9-0.9-3.2-2.3c0-0.3 0-0.3 0-0.6 0-1.2 0-2.6 0-3.8 0-2.3-0.6-4.4-1.8-6.4 -1.5-3.2-4.1-5.9-7.3-7.3 -1.8-0.9-3.5-1.8-5.9-1.8C61.1 5.9 59.1 5.9 59.1 5.9L59.1 5.9z" />
    <text x="58.5" y="77" dy=".35em" textAnchor="middle" className={props.s.quantity} data-hook="items-count">
      {props.count}
    </text>
  </SvgIcon>
);

export const Icon2 = (props: IconProps) => (
  <SvgIcon viewBox="198.6 340 197.7 166" preserveAspectRatio={true} {...props}>
    <polyline points="396.5 395.9 368.5 467.4 263.1 467.4 226.2 369.8 203.6 369.8 198.8 356.7 235.1 356.7 272 454.3 359.5 454.3 381.6 395.9" />
    <circle cx="359.5" cy="493" r="13" />
    <circle cx="275" cy="493" r="13" />
    <text
      x="315"
      y="375"
      dy=".48em"
      textAnchor="middle"
      className={classNames(props.s.quantity, props.s.withoutBackground)}
      data-hook="items-count">
      {props.count}
    </text>
  </SvgIcon>
);

export const Icon3 = (props: IconProps) => (
  <IconRebuilder initialViewBoxWidth={120} initialViewBoxHeight={105} text={props.text} aspectRatio={props.aspectRatio}>
    {({width, height, textRef, textLength}) => (
      <SvgIcon viewBox={`0 0 ${width} ${height}`} preserveAspectRatio={true} {...props}>
        <g data-hook="movable" transform={`translate(${textLength})`}>
          <circle className={props.s.bubble} cx="70" cy="50" r="50" />
          <text x="70" y="50%" dy=".32em" textAnchor="middle" className={props.s.quantity} data-hook="items-count">
            {props.count}
          </text>
        </g>
        <text x="0" y="50%" dy=".35em" ref={textRef} data-hook="free-text">
          {props.text}
        </text>
      </SvgIcon>
    )}
  </IconRebuilder>
);

export const Icon4 = (props: IconProps) => (
  <IconRebuilder initialViewBoxWidth={0} initialViewBoxHeight={105} text={props.text} aspectRatio={props.aspectRatio}>
    {({width, height, textRef}) => (
      <SvgIcon viewBox={`0 0 ${width} ${height}`} preserveAspectRatio={true} {...props}>
        <text x="0" y="83" ref={textRef} data-hook="free-text">
          {`${props.text} `}
          <tspan className={classNames(props.s.quantity, props.s.withoutBackground)} data-hook="items-count">
            ({props.count})&rlm;
          </tspan>
        </text>
      </SvgIcon>
    )}
  </IconRebuilder>
);

export const Icon5 = (props: IconProps) => (
  <IconRebuilder initialViewBoxWidth={315} initialViewBoxHeight={129} aspectRatio={props.aspectRatio} text={props.text}>
    {({width, height, textRef, textLength}) => (
      <SvgIcon viewBox={`217 356.6 ${width} ${height}`} preserveAspectRatio={true} {...props}>
        <path d="M272.6 485.2c-8.3 0-15.5-7.1-15.5-15.5s7.1-15.5 15.5-15.5 15.5 7.1 15.5 15.5S281 485.2 272.6 485.2zM272.6 464.4c-3 0-5.4 2.4-5.4 5.4s2.4 5.4 5.4 5.4c3 0 5.4-2.4 5.4-5.4S275.6 464.4 272.6 464.4z" />
        <path d="M347.6 485.2c-8.3 0-15.5-7.1-15.5-15.5s7.1-15.5 15.5-15.5 15.5 7.1 15.5 15.5S356 485.2 347.6 485.2zM347.6 464.4c-3 0-5.4 2.4-5.4 5.4s2.4 5.4 5.4 5.4c3 0 5.4-2.4 5.4-5.4S350.6 464.4 347.6 464.4z" />
        <polygon points="352.4 444.8 268.5 444.8 239.3 366.8 221.4 366.8 217.3 356.7 246.4 356.7 275.6 434 345.3 434 363.1 385.8 267.3 385.8 263.7 375.7 378 375.7 " />
        <text x="398.9" y="442.5" ref={textRef} data-hook="free-text">
          {props.text}
        </text>
        <g data-hook="movable" transform={`translate(${textLength})`}>
          <text
            x="430"
            y="445"
            textAnchor="start"
            className={classNames(props.s.quantity, props.s.withoutBackground)}
            data-hook="items-count">
            {props.count}
          </text>
        </g>
      </SvgIcon>
    )}
  </IconRebuilder>
);

export const Icon6 = (props: IconProps) => (
  <SvgIcon viewBox="132.7 354.3 329.7 134.5" preserveAspectRatio={false} {...props}>
    <path
      className={props.s.bubble}
      d="M414.3 357.3c35.7 10.7 56 47.6 45.2 83.3s-47.6 56-83.3 45.2c-35.7-10.7-56-47.6-45.2-83.3C341.7 367.4 378.6 347.1 414.3 357.3z"
    />
    <polygon points="275.6 447.1 186.9 447.1 156 365 136.9 365 132.7 354.3 163.1 354.3 194.7 436.4 267.9 436.4 286.9 385.2 198.2 385.2 194.1 374.5 302.4 374.5 " />
    <rect x="206.6" y="473.3" width="42" height="6" />
    <rect
      x="165.8"
      y="439.15"
      transform="matrix(-0.3557 -0.9346 0.9346 -0.3557 -158.2731 774.512)"
      width="44.1"
      height="6"
    />
    <path d="M196.4 460.2c-7.7 0-14.3 6.5-14.3 14.3 0 7.7 6.5 14.3 14.3 14.3 7.7 0 14.3-6.5 14.3-14.3C210.7 466.8 204.2 460.2 196.4 460.2zM196.4 482.9c-4.8 0-8.3-3.6-8.3-8.3 0-4.8 3.6-8.3 8.3-8.3 4.8 0 8.3 3.6 8.3 8.3C204.8 479.3 201.2 482.9 196.4 482.9z" />
    <path d="M261.9 460.2c-7.7 0-14.3 6.5-14.3 14.3 0 7.7 6.5 14.3 14.3 14.3 7.7 0 14.3-6.5 14.3-14.3C276.2 466.8 269.7 460.2 261.9 460.2zM261.9 482.9c-4.8 0-8.3-3.6-8.3-8.3 0-4.8 3.6-8.3 8.3-8.3 4.8 0 8.3 3.6 8.3 8.3C270.3 479.3 266.7 482.9 261.9 482.9z" />
    <text x="395" y="423" dy=".35em" textAnchor="middle" className={props.s.quantity} data-hook="items-count">
      {props.count}
    </text>
  </SvgIcon>
);

export const Icon7 = (props: IconProps) => (
  <IconRebuilder initialViewBoxWidth={140} initialViewBoxHeight={200} text={props.text} aspectRatio={props.aspectRatio}>
    {({width, height, textLength, textRef}) => (
      <SvgIcon viewBox={`0 0 ${width} ${height}`} preserveAspectRatio={false} {...props}>
        <g data-hook="movable" transform={`translate(${textLength})`}>
          <path
            className={props.s.bubble}
            d="M88.7 4.121c-35.7-10.7-73.2 10.1-83.3 45.2 -5.4 17.9-2.4 36.9 6 51.8l-7.7 26.2 25-6c6.5 5.4 14.3 8.9 22.6 11.3 35.7 10.7 73.2-10.1 83.3-45.2C144.6 51.721 124.4 14.221 88.7 4.121z"
          />
          <text x="70" y="70" dy=".35em" textAnchor="middle" className={props.s.quantity} data-hook="items-count">
            {props.count}
          </text>
        </g>
        <text x="0" y="178" data-hook="free-text" ref={textRef}>
          {props.text}
        </text>
      </SvgIcon>
    )}
  </IconRebuilder>
);

export const Icon8 = (props: IconProps) => (
  <SvgIcon viewBox="221.4 359.3 267 123" preserveAspectRatio={false} {...props}>
    <rect x="221.4" y="403.7" width="152.4" height="6" />
    <path d="M345.9 482.3h-96.4c-1.2 0-2.4-1.2-3-2.4l-19.6-70.8 6-1.8 19.1 68.5h92.3l19-68.5 6 1.8 -19.6 70.8C348.2 481.1 347 482.3 345.9 482.3z" />
    <rect
      x="259"
      y="380.1"
      transform="matrix(-0.527 -0.8498 0.8498 -0.527 109.9867 827.3946)"
      width="52.4"
      height="6"
    />
    <rect x="292.3" y="424.5" width="10.7" height="10.7" />
    <rect x="315.5" y="424.5" width="10.7" height="10.7" />
    <rect x="269.1" y="424.5" width="10.7" height="10.7" />
    <rect x="292.3" y="447.7" width="10.7" height="10.7" />
    <rect x="315.5" y="447.7" width="10.7" height="10.7" />
    <rect x="269.1" y="447.7" width="10.7" height="10.7" />
    <text
      x="390"
      y="474"
      textAnchor="start"
      className={classNames(props.s.quantity, props.s.withoutBackground)}
      data-hook="items-count">
      {props.count}
    </text>
  </SvgIcon>
);

export const Icon9 = (props: IconProps) => (
  <SvgIcon viewBox="215.5 322.7 164.9 196.4" preserveAspectRatio={true} {...props}>
    <path d="M362.5 519.2H232.8c-1.8 0-3-1.2-3-2.4L215.5 388.8c0-0.6 0-1.8 0.6-2.4 0.6-0.6 1.2-1.2 2.4-1.2h158.9c0.6 0 1.8 0.6 2.4 1.2 0.6 0.6 0.6 1.2 0.6 2.4l-14.9 127.4C365.5 518 364.3 519.2 362.5 519.2zM235.1 513.2h125l13.7-122H221.4L235.1 513.2z" />
    <path d="M334.5 388.8h-6v-27.4c0-17.9-13.7-32.7-31-32.7s-31 14.9-31 32.7V388.8h-6v-27.4c0-21.4 16.7-38.7 36.9-38.7s36.9 17.3 36.9 38.7V388.8z" />
    <text
      x="298"
      y="455"
      dy=".35em"
      textAnchor="middle"
      className={classNames(props.s.quantity, props.s.withoutBackground)}
      data-hook="items-count">
      {props.count}
    </text>
  </SvgIcon>
);

export const Icon10 = (props: IconProps) => (
  <SvgIcon viewBox="163.7 331.4 267.8 178.8" preserveAspectRatio={true} {...props}>
    <path d="M243.4 425.1l-29.7-47.9 -9.1 5.6 26.2 42.2h-67.1v10.7h3l19.1 70.2c0.6 2.4 3 4.2 5.4 4.2h97c2.4 0 4.2-1.8 4.2-3.6l19.6-70.8h4.2v-10.7H243.4zM221.4 473.3v10.7h-10.7V473.3H221.4zM210.7 461.4v-10.7h10.7v10.7H210.7zM244.7 473.3v10.7h-10.7V473.3H244.7zM233.9 461.4v-10.7h10.7v10.7H233.9zM257.8 484V473.3h10.7v10.7H257.8zM268.5 461.4h-10.7v-10.7h10.7V461.4z" />
    <path
      className={props.s.bubble}
      d="M383.4 334c35.7 10.7 56 47.6 45.2 83.3 -10.7 35.7-47.6 56-83.3 45.2 -35.7-10.7-56-47.6-45.2-83.3C310.7 344.2 348.2 323.9 383.4 334z"
    />
    <text x="365" y="400" dy=".35em" textAnchor="middle" className={props.s.quantity} data-hook="items-count">
      {props.count}
    </text>
  </SvgIcon>
);
