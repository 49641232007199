import React from 'react';
import s from '../OptionsDropdown.scss';

export interface OptionDropdownItemProps {
  label: string;
}

export const OptionDropdownItem: React.FunctionComponent<OptionDropdownItemProps> = ({label}): JSX.Element => {
  return (
    <div className={s.item}>
      <span>{label}</span>
      <i />
    </div>
  );
};
