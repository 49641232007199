const range_ = require('lodash/range')

const {
  constructUrl,
  parseUrl,
  parseUrlPattern
} = require('@wix/dbsm-common/src/dynamic-pages/urlUtils')

const getSiblingPage = async ({
  wixDataProxy,
  dynamicPagesData,
  collectionName,
  getPreviousUrl
}) => {
  const { dynamicUrl, userDefinedFilter, dynamicUrlPatternFieldsValues } =
    dynamicPagesData || {}
  if (dynamicUrl == null) {
    return null
  }

  const { pattern } = parseUrl(dynamicUrl)
  const { fields: patternFields } = parseUrlPattern(pattern)
  if (patternFields.length === 0) {
    return null
  }

  const joinedQueries = recursiveSiblingDynamicPage().reduce((result, query) =>
    result.or(query)
  )

  const { items } = await joinedQueries.find()
  const firstRecord = items.length > 0 ? items[0] : null
  return firstRecord ? constructUrl(firstRecord, dynamicUrl) : null

  function recursiveSiblingDynamicPage(currentFieldIndex) {
    if (currentFieldIndex === undefined)
      currentFieldIndex = patternFields.length - 1
    if (currentFieldIndex === -1) return []

    const indexField = patternFields[currentFieldIndex]

    let query = wixDataProxy.query(collectionName)
    query.filterTree = userDefinedFilter
    if (getPreviousUrl) {
      query = query
        .descending(...patternFields)
        .lt(indexField, dynamicUrlPatternFieldsValues[indexField])
    } else {
      query = query
        .ascending(...patternFields)
        .gt(indexField, dynamicUrlPatternFieldsValues[indexField])
    }

    range_(currentFieldIndex).forEach(
      i =>
        (query = query.eq(
          patternFields[i],
          dynamicUrlPatternFieldsValues[patternFields[i]]
        ))
    )

    return [query.limit(1)].concat(
      recursiveSiblingDynamicPage(currentFieldIndex - 1)
    )
  }
}

module.exports = ({ wixDataProxy, dynamicPagesData, collectionName }) => ({
  getNextDynamicPageUrl: () =>
    getSiblingPage({
      wixDataProxy,
      dynamicPagesData,
      collectionName,
      getPreviousUrl: false
    }),
  getPreviousDynamicPageUrl: () =>
    getSiblingPage({
      wixDataProxy,
      dynamicPagesData,
      collectionName,
      getPreviousUrl: true
    })
})
