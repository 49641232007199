const {
  GRID_ROLE,
  PAGINATION_ROLE
} = require('@wix/dbsm-common/src/connection-config/roles')

const isCompConfiguredToReadData = (role, config) =>
  [GRID_ROLE, PAGINATION_ROLE].includes(role) ||
  (config && config.properties && Object.keys(config.properties).length > 0)

module.exports = isCompConfiguredToReadData
