'use strict'

const get_ = require('lodash/get')
const flow_ = require('lodash/flow')
const Maybe = require('folktale/maybe')
const Result = require('folktale/result')

const QueryResults = require('../helpers/queryResults')
const { getFieldReferencedCollection } = require('../schemas/helpers')

const { isSameRecord } = require('./records')
const recordStoreServiceCreator = require('./service')

const createRecordStoreService = ({
  wixDataProxy,
  warmupStore,
  controllerConfig,
  logger
}) => {
  const datasetConfig = Result.fromNullable(
    controllerConfig,
    'missing controller configuration'
  ).chain(({ dataset }) =>
    Result.fromNullable(
      dataset,
      'controller configuration is missing dataset object'
    )
  )
  const collectionName = datasetConfig.chain(({ collectionName }) =>
    Result.fromNullable(
      collectionName,
      'dataset is not connected to a collection'
    )
  )

  return collectionName.map(mainCollectionName => {
    const includes = get_(controllerConfig, ['dataset', 'includes'])
    const readWriteType = get_(controllerConfig, ['dataset', 'readWriteType'])

    return recordStoreServiceCreator({
      wixDataProxy,
      warmupStore,
      mainCollectionName,
      includes,
      readWriteType,
      logger
    })
  })
}

const createRecordStoreInstance = ({
  recordStoreService,
  getFilter,
  getSort,
  getPageSize,
  prefetchedData,
  datasetId,
  filterResolver,
  getSchema,
  shouldAllowWixDataAccess
}) => byRefField => {
  const pageSize = getPageSize()
  const allowWixDataAccess = shouldAllowWixDataAccess()

  return recordStoreService.chain(service => {
    if (byRefField) {
      return Result.fromMaybe(
        getSchema().map(schema =>
          getFieldReferencedCollection(byRefField, schema)
        ),
        `cannot resolve referenced collection name for field ${byRefField}`
      ).map(referencedCollectionName => {
        return service({
          pageSize,
          sort: null,
          filter: null,
          allowWixDataAccess,
          seed: Maybe.Nothing(),
          datasetId,
          referencedCollectionName
        })
      })
    } else {
      const filter = getFilter()
      return Result.fromMaybe(
        filterResolver(filter).map(filter =>
          service({
            pageSize,
            sort: getSort(),
            filter,
            allowWixDataAccess,
            seed: QueryResults.fromWixDataQueryResults(
              prefetchedData,
              0
            ).matchWith({
              Empty: Maybe.Nothing,
              Results: flow_(QueryResults.of, Maybe.Just)
            }),
            datasetId,
            referencedCollectionName: null
          })
        ),
        'could not resolve dynamic filter'
      )
    }
  })
}

module.exports = {
  isSameRecord,
  createRecordStoreService,
  createRecordStoreInstance
}
