const get_ = require('lodash/get')
const readWriteModes = require('@wix/dbsm-common/src/dataset-configuration/readWriteModes')
const isInputRole = require('@wix/dbsm-common/src/isInputRole')

const isInSomeWritingMode = readWriteType =>
  [readWriteModes.WRITE, readWriteModes.READ_WRITE].includes(readWriteType)

const hasConnectionWithSave = connections =>
  connections.some(
    connection => get_(connection, 'config.events.onClick.action') === 'save'
  )

const hasConnectionWithInputRole = connections =>
  connections.some(connection => isInputRole(get_(connection, 'role')))

const isForm = (readWriteType, connections) =>
  isInSomeWritingMode(readWriteType) &&
  hasConnectionWithSave(connections) &&
  hasConnectionWithInputRole(connections)

module.exports = isForm
