import React from 'react';
import s from './LoadMoreButton.scss';
import {IProvidedGlobalProps, withGlobalProps} from '../../providers/GlobalPropsProvider';
import classNames from 'classnames';

interface LoadMoreButtonProps {
  loadMoreClicked: any;
}

@withGlobalProps
export class LoadMoreButton extends React.Component<LoadMoreButtonProps & IProvidedGlobalProps> {
  public render() {
    return (
      <button
        data-hook="load-more-button"
        className={classNames(s.loadMore, {[s.isMobile]: this.props.globals.isMobile})}
        onClick={this.props.loadMoreClicked}>
        {this.props.globals.textsMap.loadMoreButtonText}
      </button>
    );
  }
}
