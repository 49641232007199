'use strict'

const {
  DETAILS_DATASET_ROLE
} = require('@wix/dbsm-common/src/connection-config/roles')

const { setConnectedComponents } = require('./actions')
const reducer = require('./reducer')

const findConnectedComponents = (roles, $w) => {
  const results = []
  roles.forEach(role => {
    const components = $w('@' + role)
    components &&
      components.forEach(
        component => component && results.push({ role, component })
      )
  })
  return results
}

const resolveHandshakes = ({ datasetApi, components, controllerConfig }) =>
  components
    .filter(({ role }) => role === DETAILS_DATASET_ROLE)
    .map(({ component, role }) => ({
      controller: component,
      handshakeInfo: {
        controllerApi: datasetApi,
        controllerConfig,
        connectionConfig: component.connectionConfig,
        role: DETAILS_DATASET_ROLE
      }
    }))

module.exports = {
  findConnectedComponents,
  reducer,
  resolveHandshakes,
  setConnectedComponents
}
