'use strict'

const union = require('folktale/adt/union/union')

const deriveMethods = require('@wix/dbsm-common/src/adt/deriveMethods')
const QueryResultsADT = union('QueryResults', {
  Empty: () => ({}),
  Results: (items, totalCount, offset = 0) => ({ items, totalCount, offset })
})

deriveMethods(QueryResultsADT, {
  chain: {
    Empty: function chain(fn) {
      return this
    },
    Results: function chain(fn) {
      return fn({
        items: this.items,
        totalCount: this.totalCount,
        offset: this.offset
      })
    }
  },

  map: {
    Empty: function map(fn) {
      return this
    },
    Results: function map(fn) {
      const { items, totalCount, offset } = fn({
        items: this.items,
        totalCount: this.totalCount,
        offset: this.offset
      })
      return QueryResultsADT.Results(
        items || this.items,
        totalCount || this.totalCount,
        offset || this.offset
      )
    }
  },

  filter: {
    Empty: function filter(fn) {
      return this
    },
    Results: function filter(fn) {
      return fn({
        items: this.items,
        totalCount: this.totalCount,
        offset: this.offset
      })
        ? this
        : QueryResultsADT.Empty()
    }
  },

  orElse: {
    Empty: function orElse(fn) {
      return fn()
    },
    Results: function orElse(fn) {
      return this
    }
  },

  get: {
    Empty: get,
    Results: get
  },

  of: {
    Empty: of,
    Results: of
  }
})

function of({ items, totalCount, offset }) {
  return totalCount > 0 && Array.isArray(items)
    ? QueryResultsADT.Results(items, totalCount, offset)
    : QueryResultsADT.Empty()
}

function fromWixDataQueryResults(wixDataQueryResults, offset) {
  return wixDataQueryResults
    ? this.of({
        items: wixDataQueryResults.items,
        totalCount: wixDataQueryResults.totalCount,
        offset
      })
    : QueryResultsADT.Empty()
}

function get() {
  return this.matchWith({
    Empty: () => ({ items: [], totalCount: 0, offset: 0 }),
    Results: results => results
  })
}

const exported = {
  Empty: QueryResultsADT.Empty,
  Results: QueryResultsADT.Results,
  fromWixDataQueryResults,
  of
}

module.exports = exported
