'use strict'

const Maybe = require('folktale/maybe')
const isPlainObject_ = require('lodash/isPlainObject')

const { resolveDynamicProperties } = require('./dynamicProperties')
const emptyConnectionConfig = require('../helpers/emptyConnectionConfig')
const componentTypes = require('@wix/dbsm-common/src/componentTypes')

const swapComponentByTypeHandlers = {
  [componentTypes.Page]: ({ $w }) => $w('Document')
}

const createAdapterContext = ({
  getFieldType,
  role,
  component,
  componentType,
  componentId,
  $w,
  api
}) => {
  const swapComponentByType = swapComponentByTypeHandlers[componentType]
  const swappedComponent = swapComponentByType && swapComponentByType({ $w })

  const connectionConfig = Maybe.fromNullable(component.connectionConfig)
    .filter(isPlainObject_)
    .map(connectionConfig =>
      resolveDynamicProperties({ connectionConfig }, role, getFieldType)
    )
    .getOrElse(emptyConnectionConfig)

  const adapterContext = {
    role,
    connectionConfig,
    component: swappedComponent || component,
    componentType: (swappedComponent && swappedComponent.type) || componentType,
    componentId: (swappedComponent && swappedComponent.id) || componentId,
    api
  }

  return adapterContext
}

module.exports.createAdapterContext = createAdapterContext
