'use strict'

const RavenConstructor = require('raven-js/src/raven')
const { loggerCreator } = require('./viewerLogger')
const { consoleHandlerCreator } = require('./handlers/console')
const { ravenHandlerCreator } = require('./handlers/raven')
const { biHandlerCreator } = require('./handlers/bi')
const {
  monitoringHubHandlerCreator,
  id: monitoringHubId
} = require('./handlers/monitoringHub')
const {
  systemTracingHandlerCreator,
  id: systemTracingId
} = require('./handlers/systemTracing')
const { fedopsHandlerCreator, id: fedopsId } = require('./handlers/fedops')

const traceHandlerIds = {
  MONITORING_HUB: monitoringHubId,
  SYSTEM_TRACING: systemTracingId,
  FEDOPS: fedopsId
}

const loggerWithHandlers = ({ global, appName }) => {
  const { consoleHandler } = consoleHandlerCreator()

  const ravenHandler = ravenHandlerCreator({
    global,
    appName,
    createClients: () => ({
      Raven: new RavenConstructor(),
      UserRaven: new RavenConstructor(),
      WixDataRaven: new RavenConstructor()
    })
  })

  const biHandler = biHandlerCreator()

  const monitoringHubHandler = monitoringHubHandlerCreator({
    elementorySupport: global.elementorySupport,
    fetch: global.fetch
  })

  const systemTracingHandler = systemTracingHandlerCreator()

  const fedopsHandler = fedopsHandlerCreator()

  return loggerCreator({
    global,
    appName,
    handlerCreators: [
      consoleHandler,
      ravenHandler,
      biHandler,
      monitoringHubHandler,
      systemTracingHandler,
      fedopsHandler
    ]
  })
}

module.exports.logger = loggerWithHandlers
module.exports.traceHandlerIds = traceHandlerIds
