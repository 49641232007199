'use strict'

const addComponentEventListener = require('../addComponentEventListener')

const adapter = ({
  controllerFactory,
  controllerStore,
  applicationCodeZone
}) => {
  const itemReady = (actions, api, component) => (
    scoped$w,
    itemData,
    index
  ) => {
    const scopeId = { componentId: component.uniqueId, itemId: itemData._id }
    const controller = controllerFactory.createDetailsController(scopeId)
    controllerStore.setController(scopeId, controller)
    controller.pageReady(scoped$w.scoped)
  }

  const itemRemoved = component => itemData => {
    const scopeId = { componentId: component.uniqueId, itemId: itemData._id }
    controllerStore.removeController(scopeId)
  }

  return {
    isValidContext({ connectionConfig }) {
      return true
    },

    bindToComponent(
      {
        connectionConfig: { properties, events, filters },
        component
      },
      actions,
      api
    ) {
      addComponentEventListener(
        component,
        'onItemReady',
        itemReady(actions, api, component),
        applicationCodeZone
      )
      addComponentEventListener(
        component,
        'onItemRemoved',
        itemRemoved(component),
        applicationCodeZone
      )
    },

    currentRecordModified({ component }, actions, updatedFields) {},

    async recordSetLoaded(
      {
        connectionConfig: { properties },
        component
      },
      actions
    ) {},

    currentViewChanged(componentAdapterContext, actions) {},

    currentIndexChanged(componentAdapterContext, actions, isNewRecord) {}
  }
}

module.exports = adapter
