'use strict'
const Maybe = require('folktale/maybe')

const cloneDeep_ = require('lodash/cloneDeep')

const QueryResults = require('../helpers/queryResults')
const {
  setCurrentIndex,
  updateFields,
  refresh,
  fetchCurrentPage,
  doFetch
} = require('../records/actions')
const {
  selectCurrentRecordIndex
} = require('../dataset-controller/rootReducer')

const adapterApiCreator = ({
  dispatch,
  recordStore,
  componentAdapterContexts
}) => () => {
  const actions = {
    fetchRecordById: (recordId, byRefField) =>
      recordStore(byRefField).matchWith({
        Error: () => Maybe.Nothing(),
        Ok: ({ value: service }) => service.getRecordById(recordId)
      }),

    fetchAll: byRefField => doFetch(recordStore, 0, 1000, byRefField),

    fetchCurrentItems: state =>
      fetchCurrentPage(recordStore, state).catch(() =>
        QueryResults.Empty().get()
      ),

    fetchOne: () => doFetch(recordStore, 0, 1),

    fetch: (fromIndex, length, byRefField) =>
      doFetch(recordStore, fromIndex, length, byRefField),

    getTotalItemsCount: byRefField => {
      return recordStore(byRefField)
        .map(service => service.getMatchingRecordCount())
        .getOrElse(0)
    },

    getInitialData: () =>
      recordStore().matchWith({
        Error: () => QueryResults.Empty(),
        Ok: ({ value: service }) => service.getSeedRecords()
      }),

    setCurrentIndex: (index, suppressRefreshView) =>
      dispatch(setCurrentIndex(index, suppressRefreshView)),

    setFieldInCurrentRecordAndSynchronize: (
      field,
      value,
      componentIdToExcludeFromUpdatingComponentsBasedOnRecord
    ) => {
      dispatch(
        updateFields(
          { [field]: cloneDeep_(value) },
          componentIdToExcludeFromUpdatingComponentsBasedOnRecord
        )
      )
    },

    refresh: () => dispatch(refresh()),

    isCurrentRecordNew: state =>
      recordStore().matchWith({
        Error: () => false,
        Ok: ({ value: service }) =>
          service.isNewRecord(selectCurrentRecordIndex(state))
      }),

    isCurrentRecordPristine: state =>
      recordStore().matchWith({
        Error: () => false,
        Ok: ({ value: service }) =>
          service.isPristine(selectCurrentRecordIndex(state))
      })
  }

  const apiOf = componentAdapterContext => componentAdapterContext.api

  const api = {
    isValidContext: componentAdapterContext =>
      apiOf(componentAdapterContext).isValidContext(componentAdapterContext),

    bindToComponent: () => {
      componentAdapterContexts.map(componentAdapterContext =>
        apiOf(componentAdapterContext).bindToComponent(
          componentAdapterContext,
          actions,
          api
        )
      )
    },

    currentRecordModified: (updatedFields = [], compIdToFilter = null) =>
      componentAdapterContexts
        .filter(
          context =>
            !compIdToFilter || context.component.uniqueId !== compIdToFilter
        )
        .map(componentAdapterContext =>
          apiOf(componentAdapterContext).currentRecordModified(
            componentAdapterContext,
            actions,
            updatedFields,
            api
          )
        ),

    recordSetLoaded: () =>
      componentAdapterContexts.map(componentAdapterContext =>
        apiOf(componentAdapterContext).recordSetLoaded(
          componentAdapterContext,
          actions,
          api
        )
      ),

    currentViewChanged: () =>
      componentAdapterContexts.map(componentAdapterContext =>
        apiOf(componentAdapterContext).currentViewChanged(
          componentAdapterContext,
          actions,
          api
        )
      ),

    currentIndexChanged: () =>
      componentAdapterContexts.map(componentAdapterContext =>
        apiOf(componentAdapterContext).currentIndexChanged(
          componentAdapterContext,
          actions,
          api
        )
      )
  }

  return api
}

module.exports = adapterApiCreator
