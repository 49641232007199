import React from 'react';
import {IProvidedGlobalProps, withGlobalProps} from '../providers/GlobalPropsProvider';
import s from './Filters.scss';
import {Filter} from '../Filter/Filter';
import {SingleSelectionFilter} from '../SingleSelectionFilter/SingleSelectionFilter';
import {MultipleSelectionFilter} from '../MultipleSelectionFilter/MultipleSelectionFilter';
import {ColorFilter} from '../ColorFilter/ColorFilter';
import {FilterModel, FilterType} from '../../types/types';
import {RangeFilter} from '../RangeFilter/RangeFilter';

export interface FiltersProps extends IProvidedGlobalProps {
  clearFilters: any;
  shouldShowClearFiltersButton: boolean;
}

@withGlobalProps
export class Filters extends React.Component<FiltersProps> {
  constructor(props) {
    super(props);
  }

  private getFilterComponent(filterModel: FilterModel) {
    switch (filterModel.filterType) {
      case FilterType.COLLECTION:
        return (
          <SingleSelectionFilter
            selected={filterModel.selectedCollectionId}
            filterId={filterModel.filterId}
            options={filterModel.options.map(option => ({
              id: option.key,
              name: option.value,
            }))}
          />
        );
      case FilterType.CUSTOM_COLLECTION:
        return (
          <MultipleSelectionFilter
            filterId={filterModel.filterId}
            selectedOptionIds={filterModel.selectedCollectionId}
            options={filterModel.options.map(option => ({
              id: option.key,
              name: option.value,
            }))}
          />
        );
      case FilterType.COLOR_OPTION:
        return (
          <ColorFilter
            selected={filterModel.selectedOptionIds}
            filterId={filterModel.filterId}
            colorOptions={filterModel.options.map(option => ({rgbValue: option.rgbValue, name: option.name}))}
          />
        );
      case FilterType.LIST_OPTION:
        return (
          <MultipleSelectionFilter
            selectedOptionIds={filterModel.selectedOptionIds}
            filterId={filterModel.filterId}
            options={filterModel.options.map(option => ({
              id: option.key,
              name: option.value,
            }))}
          />
        );
      case FilterType.PRICE:
        return (
          <RangeFilter
            filterId={filterModel.filterId}
            options={filterModel.options}
            selectedRange={{
              min: filterModel.selectedPrices.minPrice,
              max: filterModel.selectedPrices.maxPrice,
            }}
          />
        );
    }
  }

  private getFilterList() {
    const {filterModels} = this.props.globals;

    return (
      <ul className={s.filterList}>
        {filterModels.map((filterModel, index) => (
          <li key={filterModel.filterId}>
            <Filter title={filterModel.title} filterType={filterModel.filterType} filterIndex={index}>
              {this.getFilterComponent(filterModel)}
            </Filter>
          </li>
        ))}
      </ul>
    );
  }

  private clearFiltersButtonClicked() {
    this.props.clearFilters();
  }

  private renderClearFiltersButton() {
    return (
      <>
        {this.props.shouldShowClearFiltersButton && (
          <button
            data-hook="clear-filters-button"
            onClick={() => this.clearFiltersButtonClicked()}
            className={s.clearFiltersButton}>
            {this.props.globals.textsMap.clearFiltersButtonText}
            <span className={s.xIcon}>X</span>
          </button>
        )}
      </>
    );
  }

  public render() {
    return (
      <section className={s.filters}>
        {
          <>
            <h2 data-hook="filters-title" className={s.filtersTitle}>
              {this.props.globals.textsMap.filtersTitleText}
            </h2>
            {this.getFilterList()}
            {this.renderClearFiltersButton()}
          </>
        }
      </section>
    );
  }
}
