import React from 'react';
import cx from 'classnames';

export interface ICell {
  className?: string;
  children?: React.ReactNode;
  dataHook?: string;
}

export const Cell: React.FunctionComponent<ICell> = ({className, children}) => {
  if (!children) {
    return null;
  }

  const classNames = cx(className, 'cell');

  return <div className={classNames}>{children}</div>;
};
