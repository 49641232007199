const webBiLogger = require('@wix/web-bi-logger').default

const {
  BI_SOURCE,
  BI_ENDPOINT: BI_VIEWER_ENDPOIINT_PREVIEW,
  BI_VIEWER_ENDPOINT: BI_VIEWER_ENDPOINT_LIVE,
  BI_ERROR_ENDPOINT,
  loggerFactory
} = require('@wix/dbsm-common/src/bi/bi')
const { getAppUrl } = require('@wix/dbsm-common/src/worker/getUrl')
const getAppVersion = require('@wix/dbsm-common/src/getAppVersion')

const APP_NAME = 'dbsm-viewer-app'

const biDefaults = {
  src: BI_SOURCE,
  ver: getAppVersion(getAppUrl(APP_NAME)),
  app_name: APP_NAME,
  app_id: 'dataBinding'
}

const getEndpointByViewMode = viewMode =>
  viewMode === 'Preview' ? BI_VIEWER_ENDPOIINT_PREVIEW : BI_VIEWER_ENDPOINT_LIVE

const setupBiLogger = (viewMode, defaults) =>
  loggerFactory(
    webBiLogger
      .factory({ endpoint: getEndpointByViewMode(viewMode) })
      .updateDefaults(Object.assign({}, biDefaults, defaults))
  )

const setupErrorBiLogger = () =>
  loggerFactory(
    webBiLogger
      .factory({ endpoint: BI_ERROR_ENDPOINT })
      .updateDefaults(biDefaults)
      .updateDefaults({ evid: 10 })
  )

module.exports.setupBiLogger = setupBiLogger
module.exports.setupErrorBiLogger = setupErrorBiLogger
