'use strict'
/* global self */
/* eslint-disable no-console */
/* global VERBOSE */

try {
  const appName = 'dbsm-viewer-app'
  const {
    viewerAutomationsClientCreator
  } = require('@wix/wix-code-automations-client')
  const createWixData = require('@wix/wix-code-platform/create-wix-data')
  const createWixDataSchemas = require('@wix/wix-code-platform/create-wix-data-schemas')
  const createApp = require('./appCreator')

  const { logger: loggerCreator } = require('../logger')
  const appLogger = loggerCreator({
    global: self,
    appName
  })

  const errorReporter = (message, error) => console.error(message, error) // eslint-disable-line no-console
  const verboseReporter = VERBOSE ? console.verbose : () => {}
  const shouldVerbose = !!VERBOSE
  const automationsClient = viewerAutomationsClientCreator({
    requestFunction: global.fetch,
    elementorySupport: global.elementorySupport
  })

  const app = createApp({
    wixDataCreator: () => ({
      wixData: createWixData(global.elementorySupport),
      wixDataSchemas: createWixDataSchemas(global.elementorySupport)
    }),
    elementorySupport: global.elementorySupport,
    errorReporter,
    verboseReporter,
    shouldVerbose,
    appLogger,
    automationsClient
  })

  module.exports = app
} catch (e) {
  console.error(e.stack)
  throw e
}
