'use strict'

const Result = require('folktale/result')
const set_ = require('lodash/set')

const addBreadcrumbResult = (value, breadcrumb) => {
  return value == null
    ? breadcrumb
    : set_(breadcrumb, ['data', 'result'], value)
}

// :: (Raven, (String, [*]) -> Breadcrumb, * -> *) -> { Wrapper, AsyncWrapper }
module.exports = (logger, createBreadcrumb, sanitise) => ({
  // withBreadcrumbs :: (String, [*] -> *) -> [*] -> *
  withBreadcrumbs: (name, fn) => (...args) => {
    const breadcrumb = createBreadcrumb(name, args)

    return Result.try(() => fn(...args)).matchWith({
      Error: ({ value: message }) => {
        breadcrumb.data = breadcrumb.data || {}
        breadcrumb.data.exception = message
        breadcrumb.level = 'error'
        logger.breadcrumb(breadcrumb)
        throw message
      },
      Ok: ({ value }) => {
        const resultBreadcrumb = addBreadcrumbResult(
          sanitise(value),
          breadcrumb
        )
        logger.breadcrumb(resultBreadcrumb)
        return value
      }
    })
  },

  // withBreadcrumbsAsync :: (String, [*] -> *) -> [*] -> Promise *
  withBreadcrumbsAsync: (name, fn) => (...args) => {
    const breadcrumb = createBreadcrumb(name, args)

    return fn(...args).then(
      value => {
        const resultBreadcrumb = addBreadcrumbResult(
          sanitise(value),
          breadcrumb
        )
        logger.breadcrumb(resultBreadcrumb)
        return value
      },
      message => {
        breadcrumb.data = breadcrumb.data || {}
        breadcrumb.data.exception = message
        breadcrumb.level = 'error'
        logger.breadcrumb(breadcrumb)
        throw message
      }
    )
  }
})
