'use strict'

const assign = props => object => Object.assign({}, object, props)

const bind = (f, g) => o => g(f(o))(o)
const registrar = list => fn => {
  list.push(fn)
  return () => {
    const index = list.indexOf(fn)
    if (index >= 0) {
      list.splice(index, 1)
    }
  }
}

module.exports.assign = assign
module.exports.bind = bind
module.exports.registrar = registrar
