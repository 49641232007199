import React from 'react';
import {ProductList} from '../ProductList/ProductList';
import {Filters} from '../Filters/Filters';
import {IPropsInjectedByViewerScript, IGallerySantaProps} from '../../types/types';
import {Sort} from '../Sort/Sort';
import s from './GalleryApp.scss';
import classNames from 'classnames';
import {IProvidedGlobalProps, withGlobalProps} from '../providers/GlobalPropsProvider';
import {Omit} from '@wix/native-components-infra/dist/src/types/types';
import autobind from 'autobind-decorator';

export enum DataHook {
  Root = 'gallery-app-root',
  Content = 'gallery-app-content',
  Container = 'gallery-app-container',
}

export type IGalleryAppProps = Omit<
  IPropsInjectedByViewerScript & IGallerySantaProps,
  IProvidedGlobalProps['globals']
> &
  IProvidedGlobalProps;

@withGlobalProps
@autobind
export class GalleryApp extends React.Component<IGalleryAppProps> {
  constructor(props) {
    super(props);
  }

  public componentDidMount() {
    this.props.registerToComponentDidLayout(this.reportAppLoaded);
  }

  private reportAppLoaded() {
    /* istanbul ignore next: hard to test it */
    if (this.props.globals.isInteractive && typeof this.props.onAppLoaded === 'function') {
      this.props.onAppLoaded();
    }
  }

  private shouldShowGalleryApp() {
    return this.props.isLoaded;
  }

  private shouldShowFilters() {
    return this.props.globals.filterModels.length !== 0 && !this.props.globals.isMobile;
  }

  private shouldShowSort() {
    return this.props.shouldShowSort && !this.props.globals.isMobile;
  }

  private renderFilters() {
    return (
      <Filters
        shouldShowClearFiltersButton={this.props.shouldShowClearFilters}
        clearFilters={this.props.clearFilters}
      />
    );
  }

  public render() {
    const {
      globals: {
        styleParams: {
          booleans: {full_width: isFullWidth},
        },
        isMobile,
      },
    } = this.props;

    return this.shouldShowGalleryApp() ? (
      <div
        data-hook={DataHook.Root}
        className={classNames(s.galleryApp, {
          deviceMobile: isMobile,
        })}>
        <div data-hook={DataHook.Content} className={classNames(s.content, {[s.fullWidth]: isFullWidth})}>
          {this.shouldShowFilters() && <aside className={s.filters}>{this.renderFilters()}</aside>}
          <div
            data-hook={DataHook.Container}
            className={classNames(s.container, {[s.filtersAndProductList]: this.shouldShowFilters()})}>
            {this.shouldShowSort() && (
              <div className={s.sortRow}>
                <Sort />
              </div>
            )}
            <ProductList hasFilters={this.props.hasSelectedFilters} />
          </div>
        </div>
      </div>
    ) : null;
  }
}
