import React from 'react';
import {withStyles} from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import {withDefaultStyleParams} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/defaultStyleParams/withDefaultStyleParams';
import {withGlobalPropsProvider} from './components/providers/GlobalPropsProvider';
import {getStyleParamsWithDefaultsFunc} from './getStyleParamsWithDefaultsFunc';

export interface IGetWrappedComponentOptions {
  entryName: string;
  Component?: React.ComponentType;
}

export function getWrappedComponent({entryName, Component}: IGetWrappedComponentOptions) {
  let wrappedComponent;

  /* istanbul ignore next: test we load minified css somewhere */
  const cssPath = [`${entryName}${!process.env.IS_MINIFIED ? '' : '.min'}.css`, `${entryName}.stylable.bundle.css`];
  wrappedComponent = withStyles(withGlobalPropsProvider(Component), {
    cssPath,
  });
  wrappedComponent = withDefaultStyleParams(wrappedComponent, getStyleParamsWithDefaultsFunc);

  return wrappedComponent;
}
