/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface GoogleProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const Google: React.SFC<GoogleProps> = ({size, ...props}) => (
  <svg viewBox="0 0 16 16" fill="currentColor" width={ size || "16" } height={ size || "16" } {...props}>
    <path fill="currentColor" d="M1.905 4.876c0 1.143 0.381 1.981 1.143 2.438 0.61 0.381 1.295 0.457 1.676 0.457 0.076 0 0.152 0 0.229 0 0 0-0.152 0.762 0.457 1.524v0c-0.99 0-4.19 0.229-4.19 2.819 0 2.667 2.971 2.819 3.505 2.819 0.076 0 0.076 0 0.076 0s0.076 0 0.152 0c0.381 0 1.371-0.076 2.286-0.457 1.143-0.61 1.752-1.6 1.752-2.971s-0.914-2.133-1.6-2.743c-0.381-0.381-0.762-0.686-0.762-0.99s0.229-0.533 0.61-0.838c0.533-0.457 0.99-1.143 0.99-2.438 0-1.143-0.152-1.829-1.067-2.362 0.076-0.076 0.457-0.076 0.61-0.076 0.457-0.076 1.219-0.152 1.219-0.533v0h-3.581c0 0-3.505 0.152-3.505 3.352zM7.543 11.733c0.076 1.067-0.838 1.905-2.21 1.981s-2.514-0.533-2.59-1.6c0-0.533 0.229-0.99 0.61-1.448 0.457-0.381 1.067-0.686 1.752-0.686 0.076 0 0.152 0 0.229 0 1.295 0 2.133 0.762 2.21 1.752zM6.629 4.114c0.381 1.219-0.152 2.514-0.99 2.743-0.076 0-0.229 0-0.305 0-0.762 0-1.524-0.762-1.752-1.752-0.152-0.61-0.152-1.143 0-1.6s0.457-0.838 0.914-0.914c0.076 0 0.229-0.076 0.305-0.076 0.914 0 1.448 0.381 1.829 1.6zM12.571 6.781v-2.286h-1.448v2.286h-2.286v1.448h2.286v2.286h1.448v-2.286h2.286v-1.448h-2.286z"
    />
  </svg>
);
Google.displayName = 'Google';
/* tslint:enable */
/* eslint-enable */
