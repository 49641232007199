'use strict'

const get_ = require('lodash/get')
const pick_ = require('lodash/pick')
const defaultDatasetConfiguration = require('@wix/dbsm-common/src/dataset-configuration/defaults')
const readWriteModes = require('@wix/dbsm-common/src/dataset-configuration/readWriteModes')
const actionTypes = require('./actionTypes')
const recordActionTypes = require('../records/actionTypes')
const rootActions = require('../dataset-controller/actions')

const canonicalConfigProperties = [
  'collectionName',
  'readWriteType',
  'includes',
  'preloadData'
]
const transientConfigProperties = ['filter', 'sort', 'pageSize']

const getCanonicalConfig = datasetConfig =>
  pick_(datasetConfig, canonicalConfigProperties)
const getTransientConfig = datasetConfig =>
  pick_(datasetConfig, transientConfigProperties)

const assignTransientData = (state, transientData) => {
  return Object.assign({}, state, {
    transientData: Object.assign({}, state.transientData, transientData)
  })
}

const initialState = {
  canonicalData: getCanonicalConfig(defaultDatasetConfiguration),
  transientData: Object.assign(
    {},
    {
      isDatasetReady: false,
      allowWixDataAccess: defaultDatasetConfiguration.preloadData
    },
    getTransientConfig(defaultDatasetConfiguration)
  )
}

module.exports = (state = initialState, action) => {
  switch (action.type) {
    case rootActions.actionTypes.INIT: {
      const allowWixDataAccess = get_(
        action,
        ['datasetConfig', 'preloadData'],
        true
      )
      return Object.assign({}, state, {
        canonicalData: Object.assign(
          {},
          state.canonicalData,
          getCanonicalConfig(action.datasetConfig)
        ),
        transientData: Object.assign(
          {},
          state.transientData,
          getTransientConfig(action.datasetConfig),
          {
            allowWixDataAccess
          }
        )
      })
    }

    case actionTypes.SET_FILTER: {
      const { filter } = action

      return assignTransientData(state, { filter, allowWixDataAccess: true })
    }

    case actionTypes.SET_SORT: {
      const { sort } = action

      return assignTransientData(state, { sort })
    }

    case actionTypes.SET_IS_DATASET_READY: {
      const { isDatasetReady } = action

      return assignTransientData(state, { isDatasetReady })
    }

    case actionTypes.SET_FIXED_FILTER_ITEM: {
      const { fixedFilterItem } = action

      return assignTransientData(state, { fixedFilterItem })
    }

    case recordActionTypes.REFRESH: {
      return assignTransientData(state, { allowWixDataAccess: true })
    }

    default:
      return state
  }
}

module.exports.isWriteOnly = state =>
  get_(state, ['canonicalData', 'readWriteType']) === readWriteModes.WRITE

module.exports.isReadOnly = state =>
  get_(state, ['canonicalData', 'readWriteType']) === readWriteModes.READ

module.exports.getReadWriteMode = state =>
  get_(state, ['canonicalData', 'readWriteType'])

module.exports.isDatasetReady = state =>
  get_(state, ['transientData', 'isDatasetReady'])

module.exports.isDatasetConfigured = state =>
  !!get_(state, ['canonicalData', 'collectionName'])

module.exports.getPageSize = state => get_(state, ['transientData', 'pageSize'])

module.exports.getFixedFilterItem = state =>
  get_(state, ['transientData', 'fixedFilterItem'])

module.exports.getFilter = state => get_(state, ['transientData', 'filter'])

module.exports.getSort = state => get_(state, ['transientData', 'sort'])

module.exports.getIncludes = state =>
  get_(state, ['canonicalData', 'includes']) || []

module.exports.getCollectionName = state =>
  get_(state, ['canonicalData', 'collectionName'])

module.exports.getPreloadData = state =>
  get_(state, ['canonicalData', 'preloadData'], true)

module.exports.shouldAllowWixDataAccess = state =>
  get_(state, ['transientData', 'allowWixDataAccess'])
