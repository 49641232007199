import {SortingDirection} from './types/types';

export const translationPath = (baseUrl, locale) => `${baseUrl}assets/locale/gallery/gallery_${locale}.json`;
export const DEFAULT_COLLECTION_ID = '00000000-000000-000000-000000000001';
export const GALLERY_PAGE_DSN = 'https://337a342c302c4c0e8c26e425e74da4c1@sentry.io/1363752';
export const SLIDER_GALLERY_DSN = 'https://c4847d204c6e4c1aa7f9ff220369cee2@sentry.io/1388877';
export const TRACK_EVENT_COLLECTION = 'All Products';
export const GALLERY_FEDOPS_APP_NAME = 'wixstores-gallery';
export const SLIDER_GALLERY_FEDOPS_APP_NAME = 'wixstores-slider-gallery';

export const sortingOptions = [
  {
    field: '',
    id: 'default',
    titleKey: 'sortTitleText',
  },
  {
    field: 'creationDate',
    direction: SortingDirection.Descending,
    id: 'newest',
    titleKey: 'sortOptionNewestText',
    settingsShouldDisplayKey: 'gallerySortNewest',
  },
  {
    field: 'comparePrice',
    direction: SortingDirection.Ascending,
    id: 'price_ascending',
    titleKey: 'sortOptionLowPriceText',
    settingsShouldDisplayKey: 'gallerySortPriceAsc',
  },
  {
    field: 'comparePrice',
    direction: SortingDirection.Descending,
    id: 'price_descending',
    titleKey: 'sortOptionHighPriceText',
    settingsShouldDisplayKey: 'gallerySortPriceDes',
  },
  {
    field: 'name',
    direction: SortingDirection.Ascending,
    id: 'name_ascending',
    titleKey: 'sortOptionNameAZText',
    settingsShouldDisplayKey: 'gallerySortNameAsc',
  },
  {
    field: 'name',
    direction: SortingDirection.Descending,
    id: 'name_descending',
    titleKey: 'sortOptionNameZAText',
    settingsShouldDisplayKey: 'gallerySortNameDes',
  },
];

export const enum Alignment {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export interface IKeyboardEvent {
  keyCode: number;
  charCode: number;
}

export const keyboardEvents: {[key: string]: IKeyboardEvent} = {
  ENTER: {keyCode: 13, charCode: 13},
  SPACEBAR: {keyCode: 32, charCode: 13},
  ARROW_UP: {keyCode: 38, charCode: 38},
  ARROW_DOWN: {keyCode: 40, charCode: 40},
  ARROW_RIGHT: {keyCode: 39, charCode: 39},
  ARROW_LEFT: {keyCode: 37, charCode: 37},
};

export const getOpenQuickViewUrl = (productId: string, externalId: string, instance: string) => {
  let url = `https://ecom.wix.com/storefront/product/${productId}?quickView=true&origin=gallery&instance=${instance}`;

  if (externalId) {
    url += `&externalId=${externalId}`;
  }

  return url;
};
