'use strict'

const union = require('folktale/adt/union/union')
const deriveMethods = require('@wix/dbsm-common/src/adt/deriveMethods')

function ToJSON(variant) {
  const { tag, type } = variant
  variant.prototype.toJSON = function() {
    const json = { tag: `${type}:${tag}` }
    Object.keys(this).forEach(key => {
      const value = this[key]
      if (value && typeof value.toJSON === 'function') {
        json[key] = value.toJSON()
      } else {
        json[key] = value
      }
    })
    return json
  }
}

const DynamicPageUrlLoadState = union('DynamicPageUrlLoadState', {
  Empty() {},
  Loading() {},
  Loaded: url => {
    if (!url) {
      throw new Error('url must exist')
    }

    return { url }
  }
}).derive(ToJSON)

deriveMethods(DynamicPageUrlLoadState, {
  hasUrl: {
    Empty: () => false,
    Loading: () => false,
    Loaded: () => true
  },
  shouldLoadUrl: {
    Empty: () => false,
    Loading: () => true,
    Loaded: () => false
  }
})

DynamicPageUrlLoadState.fromUrl = url =>
  url ? DynamicPageUrlLoadState.Loaded(url) : DynamicPageUrlLoadState.Empty()

module.exports = DynamicPageUrlLoadState
