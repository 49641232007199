import React from 'react';
import s from './RadioOption.scss';
import classNames from 'classnames';

export interface FilterOptionProps {
  id: string;
  label: string;
  onChange: Function;
  isSelected: boolean;
}

export class RadioOption extends React.Component<FilterOptionProps> {
  constructor(props) {
    super(props);
  }

  public render() {
    const {id, label, onChange, isSelected} = this.props;
    return (
      <>
        <input
          id={`radio-option-${id}`}
          data-hook="radio-option-input"
          type="radio"
          value={id}
          checked={isSelected}
          onChange={e => onChange(e)}
          className={s.input}
        />
        <label
          htmlFor={`radio-option-${id}`}
          data-hook="label"
          className={classNames('wixSdkShowFocusOnSibling', s.label, {[s.selected]: isSelected})}>
          {label}
        </label>
      </>
    );
  }
}
