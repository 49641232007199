var Experiments = /** @class */ (function () {
    function Experiments(experiments) {
        if (experiments === void 0) { experiments = {}; }
        this.experiments = experiments;
    }
    Experiments.prototype.enabled = function (specName) {
        return this.experiments.hasOwnProperty(specName) && this.experiments[specName] === 'true';
    };
    return Experiments;
}());
export { Experiments };
