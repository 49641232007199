import React from 'react';
import s from './CollapseInfoItem.scss';
import classNames from 'classnames';
import {Cell} from '../../../../Layouts/Cell/Cell';
import {InfoSectionDescription} from '../../../InfoSectionDescription/InfoSectionDescription';
import {InfoSectionLayoutId} from '../../../../../constants';
import {InfoSectionTitle} from '../../../InfoSectionTitle/InfoSectionTitle';
import {PlusTabs, MinusTabs} from '../../../../../icons/dist';

export enum DataHook {
  CollapseInfoItem = 'collapse-info-item',
  Button = 'collapse-info-button',
  MinusIcon = 'collapse-info-minus-icon',
  PlusIcon = 'collapse-info-plus-icon',
}

export interface CollapseInfoItemProps {
  description: string;
  index: number;
  title: string;
  isActive: boolean;

  handelToggleCollapse(index: number): void;
}

export class CollapseInfoItem extends React.PureComponent<CollapseInfoItemProps> {
  private readonly collapseRef = React.createRef<HTMLButtonElement>();

  private readonly onToggleCollapse = () => {
    const {handelToggleCollapse, index} = this.props;
    handelToggleCollapse(index);
  };

  private readonly onKeypressToggleCollapse = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'enter') {
      this.onToggleCollapse();
    }
  };

  public render() {
    const {description, title, isActive, index} = this.props;
    const CollapseInfoItemClass = classNames(s.infoSectionCollapse, {[s.isActive]: isActive});

    return (
      <li className={CollapseInfoItemClass} data-hook={DataHook.CollapseInfoItem}>
        <button
          ref={this.collapseRef}
          className={s.button}
          aria-expanded={isActive}
          onClick={this.onToggleCollapse}
          onKeyPress={this.onKeypressToggleCollapse}
          data-hook={DataHook.Button}>
          <InfoSectionTitle theme={InfoSectionLayoutId.Collapse} title={title} />
          {isActive ? (
            <Cell className={s.icon} data-hook={DataHook.MinusIcon}>
              <MinusTabs />
            </Cell>
          ) : (
            <Cell className={s.icon} data-hook={DataHook.PlusIcon}>
              <PlusTabs />
            </Cell>
          )}
        </button>
        <Cell className={s.description}>
          <InfoSectionDescription description={description} index={index} />
        </Cell>
      </li>
    );
  }
}
