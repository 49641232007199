'use strict'

const Maybe = require('folktale/maybe')

const dataBindingFilterResolver = dependenciesMap => ({ filterId }) =>
  Maybe.fromNullable(dependenciesMap[filterId]).chain(
    ({ controllerApi, fieldName }) =>
      Maybe.fromNullable(controllerApi.getCurrentItem()).map(item => {
        const value = item[fieldName]

        return value === undefined ? null : value
      })
  )

module.exports = dataBindingFilterResolver
