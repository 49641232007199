'use strict'

const includes_ = require('lodash/includes')
const get_ = require('lodash/get')
const uniqBy_ = require('lodash/uniqBy')

const {
  isDatasetReady,
  hasCurrentRecord,
  selectCurrentRecordIndex,
  isReadOnly,
  getPaginationData,
  selectNextDynamicPageUrl,
  selectPreviousDynamicPageUrl
} = require('../dataset-controller/rootReducer')

const getUniqueComponents = cacs =>
  uniqBy_(cacs, 'componentId').map(({ component }) => component)

const { Button, IconButton } = require('@wix/dbsm-common/src/componentTypes')
const LINKABLE_DISABLEABLE_COMPONENTS = [Button, IconButton]

const createBreadcrumbMessage = require('../helpers/createBreadcrumbMessage')

const isInputComponent = require('@wix/dbsm-common/src/isInputComponent')
const {
  DROPDOWN_OPTIONS_ROLE
} = require('@wix/dbsm-common/src/connection-config/roles')

const getDisableableInputComponents = cacs => {
  return getUniqueComponents(
    cacs
      .filter(({ role }) => role !== DROPDOWN_OPTIONS_ROLE)
      .filter(({ componentType }) => isInputComponent.byType(componentType))
  )
}

const syncEnabledStateForComponentsNotDisabledByUser = (
  store,
  componentAdapterContexts,
  logger,
  datasetId,
  recordStore
) => {
  const getMatchingRecordCount = () =>
    recordStore().matchWith({
      Error: () => 0,
      Ok: ({ value: service }) => service.getMatchingRecordCount()
    })

  const hasNextRecord = state =>
    hasCurrentRecord(state) &&
    selectCurrentRecordIndex(state) < getMatchingRecordCount() - 1

  const hasPreviousRecord = state =>
    hasCurrentRecord(state) && selectCurrentRecordIndex(state) > 0

  const hasPreviousPage = state => getPaginationData(state).offset > 0

  const hasNextPage = state => {
    const { offset, size, numPagesToShow } = getPaginationData(state)
    return size * numPagesToShow + offset < getMatchingRecordCount()
  }

  const isEditable = state => hasCurrentRecord(state) && !isReadOnly(state)
  const shouldEnableLinkedComponent = (action, state) =>
    shouldEnableByAction[action](state)
  const shouldEnableByAction = {
    new: state => !isReadOnly(state),
    save: isEditable,
    revert: isEditable,
    remove: isEditable,
    next: hasNextRecord,
    previous: hasPreviousRecord,
    nextPage: hasNextPage,
    previousPage: hasPreviousPage,
    nextDynamicPage: state => selectNextDynamicPageUrl(state).hasUrl(),
    previousDynamicPage: state => selectPreviousDynamicPageUrl(state).hasUrl(),
    loadMore: hasNextPage
  }

  const DISABLEABLE_ACTIONS = Object.keys(shouldEnableByAction)

  const getDisableableLinkedComponents = cac =>
    getUniqueComponents(
      cac
        .filter(({ componentType }) =>
          includes_(LINKABLE_DISABLEABLE_COMPONENTS, componentType)
        )
        .filter(({ connectionConfig }) =>
          includes_(
            DISABLEABLE_ACTIONS,
            get_(connectionConfig, 'events.onClick.action')
          )
        )
    )

  const updateComponentEnabledState = (
    comp,
    shouldBeEnabled,
    logger,
    datasetId
  ) => {
    if (comp.enabled !== shouldBeEnabled) {
      shouldBeEnabled ? comp.enable() : comp.disable()
      logger.breadcrumb({
        category: 'components',
        message: createBreadcrumbMessage(
          `${comp.uniqueId} changed to ${
            shouldBeEnabled ? 'enabled' : 'disabled'
          }`,
          datasetId
        )
      })
    }
  }

  const isEnabled = comp => comp.enabled
  const inputComponents = getDisableableInputComponents(
    componentAdapterContexts
  ).filter(isEnabled)
  const linkedComponents = getDisableableLinkedComponents(
    componentAdapterContexts
  ).filter(isEnabled)

  const unsubscribe = store.subscribe(() => {
    const state = store.getState()
    if (!isDatasetReady(state)) {
      return
    }

    const shouldInputComponentsBeEnabled = isEditable(state)
    inputComponents.forEach(component => {
      updateComponentEnabledState(
        component,
        shouldInputComponentsBeEnabled,
        logger,
        datasetId
      )
    })

    linkedComponents.forEach(component => {
      const action = component.connectionConfig.events.onClick.action
      const shouldBeEnabled = shouldEnableLinkedComponent(action, state)
      updateComponentEnabledState(component, shouldBeEnabled, logger, datasetId)
    })
  })

  return unsubscribe
}

module.exports = (
  store,
  componentAdapterContexts,
  logger,
  datasetId,
  recordStore
) => {
  return syncEnabledStateForComponentsNotDisabledByUser(
    store,
    componentAdapterContexts,
    logger,
    datasetId,
    recordStore
  )
}
