import {range, includes, isEqual} from 'lodash';
import {IProduct, IProductOptionSelectionItem, IOptionSelectionVariant} from '../../types/productDef';

export const QuantityCalculator = {
  defaultQuantitiesRange: range(1, 100_000),
  getQuantitiesRange: (product: IProduct, userSelections: IProductOptionSelectionItem[] = []): number[] => {
    return product.isManageProductItems
      ? QuantityCalculator.getFromUserSelections(product, userSelections)
      : QuantityCalculator.getFromProduct(product);
  },
  getFromProduct: (product: IProduct): number[] => {
    let quantities = QuantityCalculator.defaultQuantitiesRange;
    if (product.isTrackingInventory) {
      quantities = range(1, product.inventory.quantity + 1);
    } else if (product.inventory.status === 'out_of_stock') {
      quantities = [];
    }
    return quantities;
  },
  getFromUserSelections: (product: IProduct, userSelections: IProductOptionSelectionItem[]): number[] => {
    let quantities = QuantityCalculator.defaultQuantitiesRange;
    const selectedVariance = QuantityCalculator.getVariantsRepresentation(product, userSelections);
    if (selectedVariance) {
      if (product.isTrackingInventory) {
        quantities = range(1, selectedVariance.inventory.quantity + 1);
      } else if (selectedVariance.inventory.status === 'out_of_stock') {
        quantities = [];
      }
    }
    return quantities;
  },
  getVariantsRepresentation: (
    product: IProduct,
    userSelections: IProductOptionSelectionItem[]
  ): IOptionSelectionVariant => {
    let res;
    if (product.isManageProductItems && !includes(userSelections, null)) {
      const selectionsIds = userSelections.map(x => x.id);
      res = product.productItems.filter(item => isEqual(item.optionsSelections, selectionsIds))[0];
    }
    return res;
  },
};
