'use strict'

const {
  transformFilter,
  getFilterPartsByPredicate
} = require('../helpers/filters')

const currentUserFilterResolver = require('./currentUser')
const dataBindingFilterResolver = require('./databinding')

const {
  shouldResolve: shouldCurrentUserResolve
} = require('@wix/dbsm-common/src/filter-resolvers/currentUserFilterResolver')
const {
  shouldResolve: shouldDataBindingResolve
} = require('@wix/dbsm-common/src/filter-resolvers/dataBindingFilterResolver')

const shouldResolve = filterExpression =>
  shouldCurrentUserResolve(filterExpression) ||
  shouldDataBindingResolve(filterExpression)

const resolveFilter = filterResolvers => filter => {
  const resolveExpressionValue = filterExpression => {
    if (shouldCurrentUserResolve(filterExpression)) {
      return filterResolvers.currentUser()
    }

    if (shouldDataBindingResolve(filterExpression)) {
      return filterResolvers.dataBinding(filterExpression)
    }
  }

  const maybeResolvedFilter = transformFilter(
    shouldResolve,
    resolveExpressionValue,
    filter
  )

  return maybeResolvedFilter
}

// getPartsForDatabindingResolver :: Filter -> [FilterPart]
const getPartsForDatabindingResolver = filter =>
  getFilterPartsByPredicate(shouldDataBindingResolve, filter)

// hasDatabindingDependencies :: Filter -> Boolean
const hasDatabindingDependencies = filter =>
  getPartsForDatabindingResolver(filter).length > 0

// getDatabindingDependencyIds :: Filter -> [DependencyId]
const getDatabindingDependencyIds = filter =>
  getPartsForDatabindingResolver(filter).map(
    ({ filterExpression }) => filterExpression.filterId
  )

module.exports.createFilterResolver = resolveFilter
module.exports.createValueResolvers = (dependenciesMap, wixSdk) => ({
  dataBinding: dataBindingFilterResolver(dependenciesMap),
  currentUser: currentUserFilterResolver(wixSdk)
})
module.exports.hasDatabindingDependencies = hasDatabindingDependencies
module.exports.getDatabindingDependencyIds = getDatabindingDependencyIds
module.exports.getExpressions = getPartsForDatabindingResolver
