'use strict'

const noop_ = require('lodash/noop')
const { union } = require('folktale/adt/union')
const { isLocalhost } = require('@wix/dbsm-common/src/worker/getUrl')

const consoleHandlerCreator = ({
  shouldLog = isLocalhost,
  ignoredErrorMessages: _ignoredErrorMessages = []
} = {}) => {
  let ignoredErrorMessages = _ignoredErrorMessages.slice()

  const isErrorMessageIgnored = errorMessage =>
    ignoredErrorMessages.some(
      ignoredErrorMessage => errorMessage === ignoredErrorMessage
    )

  const consoleHandler = () => ({
    init: () => {},
    log: logEvent => {
      logEvent.matchWith({
        Error: ({ error }) => {
          if (shouldLog()) {
            const errorMessage = error.message ? error.message : error
            const errorStack = error.stack ? error.stack : error
            if (!isErrorMessageIgnored(errorMessage)) {
              console.error(errorStack) // eslint-disable-line no-console
            }
          }
        },
        [union.any]: noop_
      })
    }
  })

  const setIgnoredErrorMessages = _ignoredErrorMessages => {
    ignoredErrorMessages = _ignoredErrorMessages.slice()
  }

  return {
    setIgnoredErrorMessages,
    consoleHandler
  }
}

module.exports.consoleHandlerCreator = consoleHandlerCreator
