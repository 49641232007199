import React from 'react';
import s from './SingleSelectionFilter.scss';
import {IProvidedGlobalProps, withGlobalProps} from '../providers/GlobalPropsProvider';
import {RadioOption} from '../RadioOption/RadioOption';
import {DEFAULT_COLLECTION_ID} from '../../constants';

export interface ISingleSelectionFilterProps extends IProvidedGlobalProps {
  options: {id: string; name: string}[];
  filterId: number;
  selected: string;
}

@withGlobalProps
export class SingleSelectionFilter extends React.Component<ISingleSelectionFilterProps> {
  constructor(props) {
    super(props);
  }

  private handleChange(e) {
    const selectedCollectionId = e.target.value;
    this.props.globals.filterProducts(this.props.filterId, selectedCollectionId);
  }

  public render() {
    const {options} = this.props;
    const {mainCollectionId} = this.props.globals;

    const optionsWithoutMainCollection = options.filter(option => {
      return option.id !== DEFAULT_COLLECTION_ID && option.id !== mainCollectionId;
    });

    return (
      <ul className={s.options} role="radiogroup">
        <li key={mainCollectionId} className={s.option}>
          <RadioOption
            onChange={e => this.handleChange(e)}
            label={this.props.globals.textsMap.allCollectionsFilterButtonText}
            id={mainCollectionId}
            isSelected={this.props.selected === mainCollectionId}
          />
        </li>
        {optionsWithoutMainCollection.map(option => (
          <li key={option.id} className={s.option}>
            <RadioOption
              onChange={e => this.handleChange(e)}
              label={option.name}
              id={option.id}
              isSelected={this.props.selected === option.id}
            />
          </li>
        ))}
      </ul>
    );
  }
}
